import { Table, TableHead, TableFooter, TableRow, TableCell, TableBody } from "@mui/material"
import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import { TableConfig } from "./config"

interface TableProps {
    element: EditorElement
}
export const TableEl = ({ element }: TableProps) => {
    return (
        <BaseElement
            element={element}
            configObject={TableConfig}
            dndIds={element.childs.map(child => child.id)}>
            <Table>
                {element.hasHeader && <TableHead>
                    <TableRow>
                        {element.column?.map(col => <TableCell key={col.id}>{col.value}</TableCell>)}
                    </TableRow>
                </TableHead>}
                {element.hasFooter && <TableFooter>
                    {element.column?.map(col => <TableCell key={col.id}>{col.value}</TableCell>)}
                </TableFooter>}
                <TableBody>
                    {element.tableSets?.map(set =>
                        <TableRow key={set.id}>
                            {element.column?.map(({ id }) => <TableCell key={`${set.id}-${id}`}>
                                {set[id] ?? ''}
                            </TableCell>)}
                        </TableRow>)}
                </TableBody>
            </Table>
        </BaseElement>

    )
}