import { Settings, Add } from '@mui/icons-material'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import React from 'react'
import { EditorContext } from '../../useEditor'

const AddConfigElement = () => {
    const { tabs, ui } = React.useContext(EditorContext)


    const handleChangeTab = (_: any, value: string) => tabs.toggle(value)
    return (
        <ToggleButtonGroup
            value={!ui.editor ? '' : tabs.value}
            onChange={handleChangeTab}
            aria-label="screen size"
            size="small"
            exclusive
            disabled={!ui.editor}
        >
            <ToggleButton value="config" aria-label="enter fullScreen" color="standard">
                <Settings />
            </ToggleButton>
            <ToggleButton value="add" aria-label="exit fullScreen">
                <Add />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export default AddConfigElement