import { Grid, ListItem as MuiListItem, ListItemText, ListSubheader, Typography } from "@mui/material"
import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import ElementSwitcher from "../ElementSwitcher"
import { ListContainerConfig, ListItemConfig } from "./config"

interface ListContainerProps {
    element: EditorElement
}
export const ListContainer = ({ element }: ListContainerProps) => {

    return (
        <BaseElement
            element={element}
            configObject={ListContainerConfig}
            dndIds={element.childs.map(child => child.id)}>
            <Grid container direction={'column'} >
                {element.props.subheader &&
                    <Grid item xs={12} mb={2}>
                        <ListSubheader>
                            {element.props.subheader}
                        </ListSubheader>
                    </Grid>}
                {element.childs.map(child => <ElementSwitcher key={child.id} element={child} parentElement={element} />)}
            </Grid>
        </BaseElement>

    )
}
interface ListItemProps {
    element: EditorElement
    parentElement: EditorElement
}
export const ListItem = ({ element, parentElement }: ListItemProps) => {
    switch (element.listType) {
        case 'mui': return (<ListItemMui element={element} />)
        case 'ul': case 'ol': return (<ListItemLiOrOl element={element} parentElement={parentElement} />)

        default: return (<>warning: no listType for {element.listType}</>)
    }

}

const ListItemMui = ({ element }: ListContainerProps) => {
    return (
        <BaseElement
            element={element}
            configObject={ListItemConfig}>
            <MuiListItem >
                <ListItemText primary={element.props.primary} secondary={element.props.secondary ?? undefined} />
            </MuiListItem>
        </BaseElement>
    )
}
const ListItemLiOrOl = ({ element, parentElement }: ListItemProps) => {
    const position = parentElement.childs.findIndex(child => child.id === element.id) + 1
    return (
        <BaseElement
            element={element}
            configObject={ListItemConfig}>
            <element.listType start={position}>
                <Typography component="li" >
                    {element.props.primary}
                    {element.props.secondary && <Typography component={'span'} color="GrayText">{`  `}{element.props.secondary}</Typography>}
                </Typography>
                {element.childs.map(child => <ElementSwitcher key={child.id} element={child} parentElement={element} />)}
            </element.listType>
        </BaseElement>
    )
}