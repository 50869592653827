import { CardActionArea, Paper, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { ChecklistItemConfig } from '../Elements';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';
import { NoImage } from '../../..';

const CheckListItem = () => {

    return (
        <BaseConfig title={ChecklistItemConfig.label} subtitle={'Widget'}>
            <ImageChoix />
            <Text />
        </BaseConfig>
    )
}


const Text = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    return (
        <BaseForm title="Texte">
            <TextField
                fullWidth
                multiline
                label="Principal"
                value={activeElement.props.primary}
                onChange={({ target: { value: primary } }) => dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, primary } })}
                sx={{ mt: 2 }}
            />
            <TextField
                fullWidth
                multiline
                label="Secondaire"
                value={activeElement.props.secondary}
                onChange={({ target: { value: secondary } }) => dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, secondary } })}
                sx={{ mt: 2 }}
            />
        </BaseForm>
    )
}

const ImageChoix = () => {
    const { activeElement, dispatch, fileSelectorDispatch, files } = React.useContext(EditorContext)
    const handleOpen = React.useMemo(() => () => {
        fileSelectorDispatch.open({
            title: 'Choisir une image',
            active: activeElement.props.src ?? null,
            list: files.filter(({ mime }: { mime: string }) => mime.startsWith('image')),
            onSelect: ({ id: src }: any) => {
                fileSelectorDispatch.close()
                dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, src } })
            },
            onClear: () => {
                fileSelectorDispatch.close()
                dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, src: undefined } })
            },
            onClose: () => fileSelectorDispatch.close()
        })
    }, [activeElement])


    return (
        <CardActionArea
            onClick={handleOpen}
        >
            <Tooltip title="Cliquer pour choisir une image">
                {activeElement.props.src
                    ? <img style={{ width: '100%', ...activeElement.props }} src={`/download/${activeElement.props.src}`} alt="" />
                    : <Paper sx={{
                        minHeight: 200,
                        height: '100%',
                        ...(activeElement.props.src ? {
                            ...activeElement.props,
                            src: undefined,
                            backgroundImage: activeElement.props.src ? `url("/download/${activeElement.props.src}")` : undefined,
                            minHeight: 'unset',
                            height: 'unset'
                        } : {})
                    }}>
                        {!activeElement.props.src && <NoImage />}
                    </Paper>}
            </Tooltip>
        </CardActionArea>
    )
}
export default CheckListItem