import { Grid } from "@mui/material"
import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import ElementSwitcher from "../ElementSwitcher"
import { GridContainerConfig } from "./config"

interface GridContainerProps {
    element: EditorElement
}
export const GridContainer = ({ element }: GridContainerProps) => {
    return (
        <BaseElement
            element={element}
            configObject={GridContainerConfig}
            dndIds={element.childs.map(c => c.id)}
        >
            <Grid container {...element.props}>
                {element.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
            </Grid>
        </BaseElement>
    )
}