import { Avatar, Checkbox, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material"
import React from "react"
import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import ElementSwitcher from "../ElementSwitcher"
import { ChecklistConfig, ChecklistItemConfig } from "./config"

interface ChecklistProps {
    element: EditorElement
}
export const Checklist = ({ element }: ChecklistProps) => {
    return (
        <BaseElement
            element={element}
            configObject={ChecklistConfig}
            dndIds={element.childs.map(child => child.id)}>

            {element.titre && <Typography variant={'h5'} sx={{ mb: 3 }}>{element.titre}</Typography>}
            <Grid container direction={'column'} >
                {element.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
            </Grid>
        </BaseElement>
    )
}

export const ChecklistItem = ({ element }: ChecklistProps) => {

    return (
        <BaseElement
            element={element}
            configObject={ChecklistItemConfig}>
            <List>
                <ListItem >
                    {element.props.src && <ListItemAvatar>
                        <Avatar src={`/download/${element.props.src}`} variant="rounded" />
                    </ListItemAvatar>}
                    <ListItemText primary={element.props.primary} secondary={element.props.secondary ?? undefined} />
                    <ListItemSecondaryAction >
                        <Checkbox onChange={e => { e.preventDefault(); e.stopPropagation() }} />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </BaseElement>
    )
}
