import { ContentCut, FileDownload } from '@mui/icons-material'
import { Box, IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu } from '@mui/material'
import React from 'react'
import { TableurContext } from '../../useTableur'

const ExportButton = () => {
    const { activeTab, datas } = React.useContext(TableurContext)
    const [anchor, setAnchor] = React.useState<null | HTMLButtonElement>(null)

    const hasNavigator = typeof navigator !== 'undefined' && 'clipboard' in navigator

    const handleClick = (e: any, key: 'csv' | 'json', clipboard?: boolean) => {
        setAnchor(null)

        if (clipboard) {
            e.preventDefault()
            e.stopPropagation()
        }

        const download = (filename: string, url: string) => {
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }

        const toClipboard = (content: string) => {
            navigator.clipboard.writeText(content)
        }

        let content: string = '';

        switch (key) {
            case 'csv':
                content = Object.keys(datas[0]).join(clipboard ? "\t" : ';') + "\r\n"
                content += datas.map((d: any) => Object.values(d).join(clipboard ? "\t" : ';')).join('\r\n')
                return clipboard ? toClipboard(content) : download(`${activeTab}.${key}`, `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`)
            case 'json':
                content = JSON.stringify(datas)
                return clipboard ? toClipboard(content) : download(`${activeTab}.${key}`, `data:text/json;charset=utf-8,${encodeURIComponent(content)}`)

            default: return
        }



    }
    return (
        <React.Fragment>
            <IconButton onClick={({ currentTarget }) => setAnchor(currentTarget)}>
                <FileDownload />
            </IconButton>
            <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}>
                <ListItemButton onClick={(e) => handleClick(e, 'json')}>
                    <ListItemIcon>
                        <TextIcon text='json' />
                    </ListItemIcon>
                    <Box mr={hasNavigator ? 6 : undefined}>
                        <ListItemText primary="Fichier .json" />
                    </Box>
                    {hasNavigator && <ListItemSecondaryAction>
                        <IconButton size={'small'} onClick={(e) => handleClick(e, 'json', true)}>
                            <ContentCut fontSize={'small'} />
                        </IconButton>
                    </ListItemSecondaryAction>}
                </ListItemButton>
                <ListItemButton onClick={(e) => handleClick(e, 'csv')}>
                    <ListItemIcon>
                        <TextIcon text='csv' />
                    </ListItemIcon>
                    <Box mr={hasNavigator ? 6 : undefined}>
                        <ListItemText primary="Fichier .csv" />
                    </Box>
                    {hasNavigator && <ListItemSecondaryAction>
                        <IconButton size={'small'} onClick={(e) => handleClick(e, 'csv', true)}>
                            <ContentCut fontSize={'small'} />
                        </IconButton>
                    </ListItemSecondaryAction>}
                </ListItemButton>
            </Menu>
        </React.Fragment>
    )
}

interface TextIconProps {
    text: string
}
function TextIcon({ text, ...rest }: TextIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={1}
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}
        >
            <text x="2" y="17" fontSize="8" letterSpacing="1" fontWeight={100}>{text}</text>
        </svg>
    );
}



export default ExportButton