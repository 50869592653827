import { Theme } from "@mui/material"
import React from "react"
import { EditorElement, EditorValue, useEditorContextType, useEditorDispatchType, useEditorType } from "./types"
import { v4 as uuid } from 'uuid'
import { GridContainerConfig } from "./Editor/Elements/GridContainer"
import { useUi } from "../../AppProvider"

const defaultInitialValue: EditorValue = { id: '0', type: 1, props: { ...GridContainerConfig.defaultProps }, childs: [] }

export const EditorContext = React.createContext<useEditorContextType>({} as useEditorContextType)
const useEditor: useEditorType = (initialValue, onSave, fileSelectorDispatch, files: any[], documents) => {

    const { theme } = useUi()

    const [value, setValue] = React.useState<EditorValue>(initialValue ?? defaultInitialValue)
    const [activeElement, setActiveElement] = React.useState<EditorElement>(initialValue ?? defaultInitialValue)
    const [copyedElement, setCopyedElement] = React.useState<EditorElement | null>(null)

    const [fullScreen, setFullScreen] = React.useState<boolean>(false)
    const [autoSave, setAutoSave] = React.useState<boolean>(false)
    const [tab, setTab] = React.useState<string>('add')
    const [editor, toggleEditor] = React.useReducer((e) => !e, true)

    const updateTree = React.useMemo(() => (el: EditorElement) => {
        const mapChilds = (childs: EditorElement[]): EditorElement[] => childs.map(child => child.id === el.id ? el : ({ ...child, childs: mapChilds(child.childs) }))
        //@ts-ignore
        const newValue = (el.id === '0' || el.id === 0) ? el : { ...value, childs: mapChilds(value.childs) };
        setValue(newValue)
        autoSave && onSave(newValue)
    }, [value, autoSave, onSave])

    const dispatch: useEditorDispatchType = React.useMemo(() => ({
        addElement: (elementConfig, autoConfig) => {
            const newEl: EditorElement = {
                id: uuid(),
                type: elementConfig.type,
                listType: elementConfig.type === 3 ? 'mui' : activeElement.listType,
                props: elementConfig.defaultProps ?? {},
                childs: []
            }

            const newActiveEl = { ...activeElement, childs: [...activeElement.childs, newEl] }

            updateTree(newActiveEl)
            if (autoConfig) {
                setActiveElement(newEl)
                setTab('config')
            } else {
                setActiveElement(newActiveEl)
            }
        },
        selectElement: (el) => {
            setActiveElement(el)
            setTab('config')
        },
        updateElement: (el: EditorElement) => {
            updateTree(el)
            setActiveElement(el)
        },
        deleteElement: async () => {
            const filteredChilds = (childs: EditorElement[]): EditorElement[] => childs.filter(child => child.id !== activeElement.id).map(child => ({ ...child, childs: recursiveChilds(child.childs) }))
            const recursiveChilds = (childs: EditorElement[]) => filteredChilds(childs)

            const newValue = { ...value, childs: recursiveChilds(value.childs) }
            setValue(newValue)
            setActiveElement(newValue)
            autoSave && onSave(newValue)
        },
        deleteChild: (id: string) => {
            const newActiveElement = { ...activeElement, childs: activeElement.childs.filter(child => child.id !== id) }

            updateTree(newActiveElement)
            setActiveElement(newActiveElement)

        },
        copyElement: () => setCopyedElement(activeElement),
        pasteElement: () => {
            if (!copyedElement) return false
            const idsChilds = (el: EditorElement): EditorElement => ({ ...el, id: uuid(), childs: el.childs.map(child => idsChilds(child)) })
            let newElement: EditorElement = idsChilds({ ...copyedElement })

            const newActiveEl: EditorElement = { ...activeElement, childs: [...activeElement.childs, newElement] }
            updateTree(newActiveEl)
            setActiveElement(newActiveEl)

        }

    }), [activeElement, copyedElement, autoSave, onSave, updateTree, value])

    const contextValue: useEditorContextType = React.useMemo(() => ({
        ui: {
            fullScreen,
            toggle: () => setFullScreen(!fullScreen),
            editor, toggleEditor
        },
        save: {
            auto: autoSave,
            toggleAuto: () => setAutoSave(!autoSave),
            save: () => onSave(value)
        },
        tabs: {
            value: tab,
            toggle: (value) => value !== null && setTab(value)
        },
        value,
        activeElement,
        copyedElement,
        theme: theme as Theme,
        dispatch,
        files, fileSelectorDispatch,
        documents
    }), [dispatch, autoSave, fullScreen, tab, theme, activeElement, files, fileSelectorDispatch, value, onSave, copyedElement, documents, editor])

    return [
        contextValue, dispatch
    ]
}

export default useEditor