interface NoImageProps {
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    sx?: React.CSSProperties
}
export const NoImage = ({ color, sx }: NoImageProps) => {
    const style: React.CSSProperties = { fill: 'none', stroke: color ?? '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '20px' }
    return (
        <svg viewBox="-100 0 712 512" style={sx}>
            <g data-name="Layer 2" id="Layer_2">
                <g data-name="E439, Image, nature, round" id="E439_Image_nature_round">

                    <circle style={style} cx="256" cy="256" r="246" />

                    <polyline style={style} points="68.49 415.24 180.69 230.9 262.45 360.49 341.98 486.56" />

                    <polyline style={style} points="262.45 360.49 311.22 271.06 433.66 426.15" />

                    <circle style={style} cx="140.53" cy="160.61" r="30.12" />

                    <line style={style} x1="0" y1="342" x2="512" y2="170" />
                </g>
            </g>
        </svg>
    )
}