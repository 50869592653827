import React from 'react'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { useLangage } from '../AppProvider'

interface GlobalAdderProps<T> {
    rounded?: boolean
    icon?: React.ElementType
    label?: string
    modal?: boolean
    title?: string
    initialState?: Partial<T>
    content: (state: Partial<T>, setState: React.Dispatch<React.SetStateAction<Partial<T>>>) => JSX.Element
    isValide: (state: Partial<T>) => boolean
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
    onCreate: (state: Partial<T>) => void
}
export const GlobalAdder = ({
    rounded = true,
    icon: Icon = Add,
    label,
    modal,
    title,
    initialState = {},
    content,
    isValide,
    maxWidth = 'md',
    onCreate
}: GlobalAdderProps<any>) => {
    const { trad } = useLangage()
    const [open, setOpen] = React.useState<boolean>(false)
    const [state, setState] = React.useState(initialState)

    const openDialog = () => setOpen(true)
    const closeDialog = () => setOpen(false)
    const handleCreate = () => {
        onCreate(state)
        closeDialog()
    }

    React.useEffect(() => {
        setState(initialState)
    }, [open])
    return (
        <React.Fragment>
            <Tooltip title={label ?? trad.Create}>
                {rounded
                    ? <IconButton onClick={openDialog}>
                        <Icon />
                    </IconButton>
                    : <Button>
                        <Icon />
                    </Button>
                }
            </Tooltip>

            <Dialog
                open={open}
                onClose={!modal ? closeDialog : () => { }}
                fullWidth={true}
                maxWidth={maxWidth}
            >
                <DialogTitle>
                    <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        justifyContent='space-beetwin'
                        width={'100%'}
                    >
                        <Box flexGrow={1}>
                            <Typography variant='h6'>
                                {title ?? label ?? trad.Create}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={closeDialog}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {content(state, setState)}
                </DialogContent>

                <DialogActions>
                    <Button color='secondary' onClick={closeDialog}>
                        {trad.cancel}
                    </Button>
                    <Button disabled={isValide(state)} onClick={handleCreate}>
                        {trad.Create}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}