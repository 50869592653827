import React from 'react'
import { LangContext } from '../lang/LangProvider'
import { ControledInput } from '.'
import { AvailableLocalesTypes } from '../types/LocalisedStringValue'
import { MenuItem } from '@mui/material'
import { useLangage } from '../AppProvider'

interface LangSelectorProps {
    value?: AvailableLocalesTypes
    onSave: (value: AvailableLocalesTypes) => void
}
export const LangSelector = ({
    value,
    onSave
}: LangSelectorProps) => {
    const { locales, trad } = useLangage()

    return (
        <ControledInput
            label={trad.Lang}
            value={value ?? locales[0]}
            onSave={onSave}
            select
        >
            {locales.map(locale => <MenuItem key={locale} value={locale}>{locale}</MenuItem>)}
        </ControledInput>
    )
}