import { EditorElement } from "../../types"
import { Alert } from "./Alert"
import { Carousel } from "./Carousel"
import { Checklist, ChecklistItem } from "./Checklist"
import { Divider } from "./Divider"
import { GridContainer } from "./GridContainer"
import { GridItem } from "./GridItem"
import { Image } from "./Image"
import { DocumentLink, Externallink } from "./Links"
import { ListContainer, ListItem } from "./ListContainer"
import { OngletContainer } from "./OngletContainer"
import { OngletItem } from "./OngletItem"
import { StepContainer } from "./StepContainer"
import { StepItem } from "./StepItem"
import { TableEl } from "./Table"
import { Typography } from "./Typography"
import { Video } from "./Video"

interface ElementSwitcherProps {
    element: EditorElement
    parentElement?: EditorElement
}
const ElementSwitcher = ({ element, parentElement }: ElementSwitcherProps) => {
    switch (element.type) {
        case 1: return <GridContainer element={element} />
        case 2: return <GridItem element={element} />
        case 3: return <ListContainer element={element} />
        case 4: case 5: return <Typography element={element} />
        case 6: return <Divider element={element} />
        case 7: return <Image element={element} />
        case 8: return <Video element={element} />
        case 9: return <Alert element={element} />
        case 10: return <Carousel element={element} />
        case 11: return <Checklist element={element} />
        case 12: return <ListItem element={element} parentElement={parentElement!} />
        case 13: return <ChecklistItem element={element} />
        case 14: return <TableEl element={element} />
        case 15: return <DocumentLink element={element} />
        case 16: return <Externallink element={element} />
        case 17: return <StepContainer element={element} />
        case 18: return <StepItem element={element} />
        case 19: return <OngletContainer element={element} />
        case 20: return <OngletItem element={element} />

        default: return <>Not implemented element.type {element.type}<br /><hr /> {JSON.stringify(element)}</>
    }
}

export default ElementSwitcher