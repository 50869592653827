import type { Column } from '../../type'

import React from 'react'

import { Avatar, Chip } from '@mui/material'

import { TableurContext } from '../../useTableur'

interface ChipCellProps {
    column: Column<any>
    data: any
}
const ChipCell = ({ data, column: c }: ChipCellProps) => {
    const context = React.useContext(TableurContext)
    const dataFunctionOptions = { data, field: c.field, value: data[c.field], ...context! }
    if (!c.chip) return <></>

    return (
        data[c.field]
            ? <Chip
                avatar={typeof c.chip === 'object' && c.chip.avatar && c.chip.avatar(dataFunctionOptions) ? <Avatar src={c.chip.avatar(dataFunctionOptions)} /> : undefined}
                label={typeof c.chip === 'object'
                    ? c.chip.label(dataFunctionOptions)
                    : data[c.field]}
            />
            : <></>
    )
}

export default ChipCell