import type { UserFunctionType } from '../AppAdmin/types'

import React from 'react'

import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useLangage } from '../AppProvider'


interface FormationFunctionAdderProps {
    onAdd: (f: UserFunctionType) => any
    functions: UserFunctionType[]
}
export const FormationFunctionAdder = ({
    onAdd,
    functions
}: FormationFunctionAdderProps) => {
    const { locale } = useLangage()
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
    const addHandler = (f: UserFunctionType) => {
        setAnchor(null)
        onAdd(f)
    }

    return (
        <React.Fragment>
            <Tooltip title={functions.length > 0 ? 'Ajouter une fonction' : 'Toutes les fonctions ont été ajoutées.'}>
                <span>

                    <IconButton
                        disabled={functions.length === 0}
                        onClick={({ currentTarget }) => setAnchor(currentTarget)}
                    >
                        <Add />
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}
            >
                {functions.map(f => <MenuItem key={f.id} onClick={() => addHandler(f)}>{f.name[locale]}</MenuItem>)}
            </Menu>
        </React.Fragment>
    )
}
