import { OngletContainerConfig } from '../Elements/OngletContainer';
import { OngletItemConfig } from '../Elements/OngletItem';
import BaseConfig from './BaseConfig';

const OngletContainer = () => {

    return (
        <BaseConfig
            title={OngletContainerConfig.label}
            subtitle={'Widgets'}
            adder={[
                OngletItemConfig
            ]} />
    )
}


export default OngletContainer