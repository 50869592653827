import { Alert as MuiAlert, AlertTitle, Grid } from "@mui/material"
import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import ElementSwitcher from "../ElementSwitcher"
import { AlertConfig } from "./config"

interface AlertProps {
    element: EditorElement
}
export const Alert = ({ element }: AlertProps) => {
    return (
        <BaseElement element={element} configObject={AlertConfig} {...element.props} icon={element.props.icon ? element.props.icon : undefined}>
            <MuiAlert {...element.props}>
                {element.props.title && <AlertTitle>{element.props.title}</AlertTitle>}
                <Grid container direction={'column'} flexGrow={1}>
                    {element.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
                </Grid>
            </MuiAlert>
        </BaseElement>
    )
}