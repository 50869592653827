import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material"
import React from "react"
import { EditorContext } from "../../useEditor"

interface TuileProps {
    item: any
}
const Tuile = ({ item }: TuileProps) => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const isDisabled = !item.allowedBy.includes(activeElement.type)
    const handleAdd = () => dispatch.addElement(item, item.autoConfig)
    return (
        <Card
            sx={{
                display: 'flex',
                flexBasis: .5,
                flexGrow: 0,
                maxWidth: 'calc(50% - 4px)',
                minWidth: 'calc(50% - 4px)',
            }}>
            <CardActionArea disabled={isDisabled} onClick={handleAdd}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, color: isDisabled ? 'GrayText' : undefined }}>
                    <item.icon sx={{ fontSize: 40 }} />
                    <Typography>{item.label}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default Tuile