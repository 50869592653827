import { DesktopMacTwoTone, PhoneIphoneTwoTone, TabletMacTwoTone } from '@mui/icons-material';
import { Box, Divider, FormControlLabel, FormLabel, Slider, Switch } from '@mui/material'
import React from 'react'
import { TexteConfig, TitreConfig } from '../Elements';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';

const GridItem = () => {

    return (
        <BaseConfig
            title={'Colonne'}
            subtitle={'Conteneur'}
            adder={[TitreConfig, TexteConfig]}
        >
            <ResponsiveSelector />
        </BaseConfig>

    )
}

const ResponsiveSelector = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const isResponsive = activeElement.props.xs === activeElement.props.md && activeElement.props.xs === activeElement.props.lg

    const handleChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch.updateElement({
            ...activeElement, props: {
                ...activeElement.props,
                ...(checked
                    ? { xs: activeElement.props.xs, md: activeElement.props.xs, lg: activeElement.props.xs }
                    : { xs: 12, md: 6, lg: 2 })

            }
        })
    };

    const handleChangeResponsiveSize = (_: any, value: number | number[], __: any) => {
        const localValue = value === 0 ? false : value
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, xs: localValue, md: localValue, lg: localValue } })
    }

    const handleChangeResponsiveAutoSize = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch.updateElement({
            ...activeElement, props: {
                ...activeElement.props,
                ...(checked
                    ? { xs: true, md: true, lg: true }
                    : { xs: 12, md: 12, lg: 12 })
            }
        })
    }

    return (
        <BaseForm>
            <FormLabel component="legend">Taille de la colonne</FormLabel>

            <FormControlLabel control={<Switch
                checked={isResponsive}
                onChange={handleChange}
            />} label="Responsive" />
            {isResponsive === true
                ? <React.Fragment>
                    <FormControlLabel control={<Switch
                        checked={activeElement.props.xs === true}
                        onChange={handleChangeResponsiveAutoSize}
                    />} label="Auto." />
                    {activeElement.props.xs !== true &&
                        <Slider
                            min={0}
                            max={12}
                            step={1}
                            value={activeElement.props.xs === false ? 0 : activeElement.props.xs}
                            onChange={handleChangeResponsiveSize}
                        />}
                </React.Fragment>
                : <React.Fragment>
                    <Divider />
                    <Box mt={1} display={'flex'} flexDirection={'column'} gap={1} padding={.5}>
                        <Sizer Icon={PhoneIphoneTwoTone} field={'xs'} />
                        <Sizer Icon={TabletMacTwoTone} field={'md'} />
                        <Sizer Icon={DesktopMacTwoTone} field={'lg'} />
                    </Box>
                </React.Fragment>}
        </BaseForm>
    )
}
interface SizerProps {
    Icon: React.ElementType
    field: string
}
const Sizer = ({ Icon, field }: SizerProps) => {
    const { activeElement, dispatch } = React.useContext(EditorContext)

    const changeAutoSize = (_: any, checked: boolean) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, [field]: checked === true ? true : 12 } })
    }

    const changeSize = (_: any, value: number | number[]) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, [field]: value === 0 ? false : value } })
    }


    return (
        <Box display={'flex'} gap={1} alignItems={'center'}>
            <Box>
                <Icon />
            </Box>
            <Box>
                <FormControlLabel control={<Switch
                    name={field}
                    checked={activeElement.props[field] === true}
                    onChange={changeAutoSize}
                />} label="Auto." />
            </Box>
            <Box flexGrow={1}>
                {activeElement.props[field] !== true &&
                    <Slider
                        name={field}
                        min={0}
                        max={12}
                        step={1}
                        value={activeElement.props[field] === false ? 0 : activeElement.props[field]}
                        onChange={changeSize}
                    />}
            </Box>
        </Box>
    )
}



export default GridItem