import { TextField, MenuItem } from '@mui/material'
import { useLangage } from '../AppProvider'

export type UserRoleTypes = 'admin' | 'manager' | 'user'

interface UserRoleSelectorInterface {
    value: UserRoleTypes
    onChange: (role: UserRoleTypes) => void
    disabled?: boolean
}
export const UserRoleSelector = ({ value, onChange, disabled }: UserRoleSelectorInterface) => {
    const { trad } = useLangage()
    return (
        <TextField variant='outlined' label={trad.Role ?? 'Rôle'} disabled={disabled}
            value={value || 'user'}
            onChange={({ target: { value } }) => onChange(value as UserRoleTypes)}
            fullWidth select >
            <MenuItem value='admin'>{trad.AdminRole ?? 'Administrateur'}</MenuItem>
            <MenuItem value='manager'>{trad.ManagerRole ?? 'Manageur'}</MenuItem>
            <MenuItem value='user'>{trad.userRole ?? 'Utilisateur'}</MenuItem>
        </TextField>
    )
}