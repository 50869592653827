import { ChevronLeft } from '@mui/icons-material'
import { Box, Button, Divider, ListSubheader, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { TableurContext } from '../../useTableur'

const ButtonPerPage = () => {
    const { activeTab, paginationObject: { nbPerPage, options }, dispatch: { setNbPerPage } } = React.useContext(TableurContext)
    const [anchor, setAnchor] = React.useState<null | HTMLButtonElement>(null)

    const handleClick = (value: number) => {
        setAnchor(null)
        setNbPerPage(prev => ({ ...prev, [activeTab]: value }))
    }
    return (
        <Box>

            <Button size='small' onClick={({ currentTarget }) => setAnchor(currentTarget)}>
                <Typography variant="caption">Afficher {nbPerPage === -1 ? 'toutes les' : nbPerPage} lignes</Typography>
                <ChevronLeft fontSize={'small'} sx={{ transform: `rotate(${Boolean(anchor) ? '' : '-'}90DEG)` }} />
            </Button>
            <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <ListSubheader>Nombre de ligne à afficher :</ListSubheader>
                {options.map((o) =>
                    <MenuItem key={o} onClick={() => handleClick(o)}>{o}</MenuItem>
                )}
                <Divider />
                <MenuItem onClick={() => handleClick(-1)} >Toutes</MenuItem>
            </Menu>
        </Box>
    )
}

export default ButtonPerPage