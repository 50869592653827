import React from 'react'
import BaseConfig, { BaseForm } from './BaseConfig'
import { ExternalLinkConfig } from '../Elements/Links'
import { Box } from '@mui/material'
import { EditorContext } from '../../useEditor'
import { Input } from '../../..'

const ExternalLink = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const [titre, setTitre] = React.useState('')
    const [value, setValue] = React.useState('')

    const handleSave = () => {
        dispatch.updateElement({ ...activeElement, titre, value })
    }

    React.useEffect(() => {
        setTitre(activeElement.titre ?? '')
        setValue(activeElement.value ?? '')
    }, [activeElement])
    return (
        <BaseConfig
            title={ExternalLinkConfig.label}
            subtitle={'Lien'}
        >
            <BaseForm >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Input
                        label="Titre du lien"
                        value={titre}
                        onChange={e => setTitre(e.target.value)}
                        onBlur={handleSave}
                        onKeyUp={({ key }) => key === 'Enter' && handleSave()}
                    />
                    <Input
                        label="Adresse du lien"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        onBlur={handleSave}
                        onKeyUp={({ key }) => key === 'Enter' && handleSave()}
                    />
                </Box>
            </BaseForm>

        </BaseConfig>
    )
}

export default ExternalLink