import React from 'react';
import ReactDOM from 'react-dom/client';
import AppAdmin from './AppAdmin';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    // <React.StrictMode>
    <AppAdmin />
    // </React.StrictMode>
)