import BaseConfig from './BaseConfig';

const Divider = () => {

    return (
        <BaseConfig title={'Séparation'} subtitle={'Textes'} />
    )
}


export default Divider