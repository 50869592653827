import React from 'react'

import { TableCell, Checkbox } from '@mui/material'

import { TableurContext } from '../useTableur'

interface RowCheckerProps {
    checked: boolean,
    id: string
}
const RowChecker = ({ checked: isChecked, id }: RowCheckerProps) => {
    const { activeTab, checked, dispatch: { setChecked } } = React.useContext(TableurContext)

    const handleClick = () => setChecked(prev => ({
        ...prev,
        [activeTab]: checked ? isChecked ? checked.filter(c => c !== id) : [...checked, id] : [id]
    }))

    return (
        <TableCell padding="checkbox">
            <Checkbox
                color="primary"
                checked={isChecked}
                onChange={handleClick}
            />
        </TableCell>
    )
}

export default RowChecker