import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"

import { useLangage } from "../../AppProvider"

export interface DeleteModaleProps {
    open: boolean
    handleClose: () => void
    confirmDelete: () => void
}

const DeleteModale = ({ open, handleClose, confirmDelete }: DeleteModaleProps) => {
    const { trad } = useLangage()
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{ zIndex: 20000 }}
        >
            <DialogTitle>{trad.deleteButtonDefaultTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {trad.deleteButtonDefaultLine1}
                </DialogContentText>
                <DialogContentText>
                    {trad.deleteButtonDefaultLine2}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleClose}>{trad.cancel}</Button>
                <Button variant="outlined" color="error" onClick={confirmDelete}>{trad.delete}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteModale