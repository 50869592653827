import type { Column } from '../type'

import React from 'react'

import { TableHead, TableRow, TableCell, SxProps, TableSortLabel, Theme } from '@mui/material'

import { TableurContext } from '../useTableur'

import HeadButtons from './Buttons/HeadButtons'
import CellChecker from './CellChecker'

const defaultSx = (column: Column<any>, theme: Theme): SxProps<Theme> => ({
    width: (column.header?.small || column.avatar) ? '1px' : undefined,
    textAlign: column.avatar ? 'center' : undefined,
    ...(column.sticky ? { position: 'sticky', left: 0, background: theme => theme.palette.background.default, display: 'table-cell', zIndex: 10 } : {})
})

const Head = () => {
    const { columns, hiddenFields, hasButtons, checked, sortEnd, collapsable } = React.useContext(TableurContext)
    return (
        <TableHead>
            <TableRow>
                {collapsable && <TableCell sx={{ width: '1px' }} />}
                {sortEnd && <TableCell sx={{ width: '1px' }} />}
                {checked && <CellChecker />}
                {columns.filter(c => hiddenFields ? !hiddenFields.includes(c.field) : !c.hidden)
                    .map((c, i) => c.onSort
                        ? <SortCell key={`${c.field}${i}`} column={c} />
                        : <TableCell key={`${c.field}${i}`} sx={{ ...((theme) => defaultSx(c, theme)), ...c.header?.sx }}>
                            {c.header?.label
                                ? typeof c.header.label === 'function'
                                    ? c.header.label()
                                    : c.header.label
                                : c.field}
                        </TableCell>)}
                {hasButtons && <HeadButtons />}
            </TableRow>
        </TableHead>
    )
}


const SortCell = ({ column: c }: { column: Column<any> }) => {
    const { sort, dispatch } = React.useContext(TableurContext)
    return (
        <TableCell
            sx={{ ...((theme) => defaultSx(c, theme)), ...c.header?.sx }}
            sortDirection={sort?.order}
        >
            <TableSortLabel
                active={sort?.field === c.field}
                direction={sort?.order}
                onClick={() => dispatch.sort(c.field)}
            >
                {c.header?.label
                    ? typeof c.header.label === 'function'
                        ? c.header.label()
                        : c.header.label
                    : c.field}
            </TableSortLabel>
        </TableCell>
    )
}

export default Head