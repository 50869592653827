import { ChevronRight } from "@mui/icons-material"
import { Box, CardActionArea, Collapse, IconButton, Typography } from "@mui/material"
import React from "react"
import { AdderConfigGroup } from "../../types"
import { EditorContext } from "../../useEditor"
import Tuile from "./Tuile"

interface GroupInterface {
    config: AdderConfigGroup
}
const Group = ({ config }: GroupInterface) => {
    const { theme } = React.useContext(EditorContext)
    const [open, setOpen] = React.useState<boolean>(true)

    return (
        <Box
            sx={{
                boxShadow: theme.shadows.at(1),
                marginBottom: '1px'

            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    flexWrap: 'nowrap',
                    gap: 1,
                    bgcolor: 'white',
                    p: 0,
                    cursor: 'pointer',
                    backgroundColor: theme.palette.grey['400'],
                    boxShadow: theme.shadows.at(1)
                }}
                component={CardActionArea}
                onClick={() => setOpen(!open)}
            >
                <Typography component={'div'} mt={1}>
                    <ChevronRight sx={{ transform: open ? `rotate(90deg)` : undefined }} />
                </Typography>
                <Typography component={'div'} >
                    {config.label}
                </Typography>
            </Box>
            <Collapse in={open}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.grey['300'],
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        p: 1
                    }}
                >
                    {config.items.map((item, index) => <Tuile key={index} item={item} />)}
                </Box>
            </Collapse>
        </Box>
    )
}



export default Group