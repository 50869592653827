import { CardActionArea, Paper, Tooltip } from '@mui/material';
import React from 'react';
import { NoImage } from '../../..';
import { EditorContext } from '../../useEditor';
import BaseConfig from './BaseConfig';



const Image = () => {
    return (
        <BaseConfig title={'Vidéo'} subtitle={'Médias'} debug>
            <VideoChoix />
        </BaseConfig>
    )
}


const VideoChoix = () => {
    const { activeElement, dispatch, fileSelectorDispatch, files } = React.useContext(EditorContext)
    const handleOpen = React.useMemo(() => () => {
        fileSelectorDispatch.open({
            title: 'Choisir une vidéo',
            active: activeElement.props.src ?? null,
            list: files.filter(({ mime }: { mime: string }) => mime.startsWith('video')),
            onSelect: ({ id: src }: any) => {
                fileSelectorDispatch.close()
                dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, src } })
            },
            onClose: () => fileSelectorDispatch.close()
        })
    }, [activeElement, dispatch, fileSelectorDispatch, files])


    return (
        <CardActionArea
            onClick={handleOpen}
        >
            <Tooltip title="Cliquer pour choisir une vidéo">
                {activeElement.props.src
                    ? <video src={`/download/${activeElement.props.src}`} style={{ width: '100%' }} controls />
                    : <Paper sx={{
                        minHeight: 200,
                        height: '100%',
                        ...(activeElement.props.src ? {
                            ...activeElement.props,
                            src: undefined,
                            backgroundImage: activeElement.props.src ? `url("/download/${activeElement.props.src}")` : undefined,
                            minHeight: 'unset',
                            height: 'unset'
                        } : {})
                    }}>
                        {!activeElement.props.src && <NoImage />}
                    </Paper>}
            </Tooltip>
        </CardActionArea>
    )
}

export default Image