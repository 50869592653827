import { Edit } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { DataFunctionOption } from '../../type'
import { TableurContext } from '../../useTableur'

const EditButton = ({ id, data, url, urlFn, label }: { id: string, data: any, url: string, urlFn: ((options: DataFunctionOption<any>) => string) | undefined, label?: string }) => {
    const context = React.useContext(TableurContext)
    return (
        <Tooltip title={label ?? "Editer"}>
            <IconButton
                component={Link}
                to={urlFn
                    ? urlFn({ ...context, data, field: '', value: '' })
                    : url.replace(':id', id)}
                size={'small'}>
                <Edit fontSize={'small'} />
            </IconButton>
        </Tooltip>
    )
}

export default EditButton