import { Divider as MuiDivider } from "@mui/material"
import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import { DividerConfig } from "./config"

interface DividerProps {
    element: EditorElement
}
export const Divider = ({ element }: DividerProps) => {
    return (
        <BaseElement element={element} configObject={DividerConfig}>
            <MuiDivider />
        </BaseElement>
    )
}