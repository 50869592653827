import React from "react"

import { Box, Card, CardActionArea, CardMedia, Grid } from "@mui/material"

import { FileSelectorContext } from "./useFileSelector"
import { type FileType } from "."


interface FileItemProps {
    file: FileType
}
export const FileItem = ({ file }: FileItemProps) => {
    const { dispatch } = React.useContext(FileSelectorContext)
    const handleClick = () => dispatch.select(file.id)
    const handleDoubleClick = () => dispatch.selectAndValidate(file)
    return (
        <Grid item xs={12} md={4} lg={3} >
            <CardActionArea onClick={handleClick} onDoubleClick={handleDoubleClick}>
                <Card sx={{ aspectRatio: '16/9' }}>
                    {file.mime.includes('video')
                        ? <video src={`/download/${file.id}`} />
                        : <CardMedia image={`/download/${file.id}`} sx={{ height: '100%' }} />}
                    <Box sx={{ width: '100%', position: 'absolute', bottom: 0, p: 1, backgroundColor: 'rgba(256,256,256,.4)' }}>
                        {file.name}
                    </Box>
                </Card>
            </CardActionArea>
        </Grid>
    )
}