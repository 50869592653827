import { Active, closestCenter, DndContext, DragEndEvent, Modifiers, MouseSensor, Over, TouchSensor, useSensor, useSensors } from "@dnd-kit/core"
import { SortableContext, SortableData } from "@dnd-kit/sortable"
import React from "react"

interface DndProps {
    ids: string[]
    children: JSX.Element[] | JSX.Element | React.ReactNode
    onDragEnd: (oldIndex: number, newIndex: number) => void
    modifiers?: Modifiers
}
const Dnd = ({ ids, children, onDragEnd, modifiers }: DndProps) => {

    const activationConstraint = {
        distance: 15,
    }
    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint }), useSensor(TouchSensor, { activationConstraint }));
    const handleDragEnd = ({ active, over }: DragEndEvent): void => {
        const { data: { current: { sortable: { index: oldIndex } } } } = active as Active & { data: { current: SortableData } }
        const { data: { current: { sortable: { index: newIndex } } } } = over as Over & { data: { current: SortableData } }

        if (oldIndex > -1 && newIndex > -1) onDragEnd(oldIndex, newIndex)

    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={modifiers}
            accessibility={undefined}
        >
            <SortableContext items={ids}>
                {children}
            </SortableContext>
        </DndContext>
    )
}

export default Dnd