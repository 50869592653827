import React from 'react'

import { Box, IconButton, ListItemSecondaryAction, ListItemText, ListSubheader, Menu, MenuItem, Switch, Tooltip } from '@mui/material'
import { Settings } from '@mui/icons-material'

import { TableurContext } from '../../useTableur'

const FieldHidder = () => {
    const { activeTab, hiddenFields, columns, dispatch, datas, translation } = React.useContext(TableurContext)
    const [anchor, setAnchor] = React.useState<null | HTMLButtonElement>(null)

    const handleClick = (field: string) => dispatch.setHiddenFields(prev => ({
        ...prev,
        [activeTab]: hiddenFields
            ? hiddenFields.includes(field) ? prev[activeTab].filter(p => p !== field) : [...prev[activeTab], field]
            : [...columns].filter(c => c.hidden).map(c => c.field).includes(field)
                ? [...columns].filter(c => c.hidden).map(c => c.field).filter(c => c !== field)
                : [...[...columns].filter(c => c.hidden).map(c => c.field), field]
    }))
    return (
        <React.Fragment>
            <Tooltip title={translation?.showHideField ?? 'Afficher/masquer des champs'}>
                <IconButton onClick={({ currentTarget }) => setAnchor(currentTarget)} color={(hiddenFields ?? columns.filter(c => c.hidden)).length > 0 ? 'warning' : 'primary'}>
                    <Settings />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}>
                <ListSubheader>{translation?.showHideField ?? 'Afficher/masquer des champs'}</ListSubheader>
                {columns.map((c, i) => (
                    <MenuItem key={i} onClick={() => handleClick(c.field)}>
                        <Box sx={{ mr: 12 }}>
                            <ListItemText primary={c.field} secondary={datas ? typeof datas[0][c.field] : undefined} />
                        </Box>
                        <ListItemSecondaryAction>
                            <Switch checked={hiddenFields ? !hiddenFields.includes(c.field) : !c.hidden} />
                        </ListItemSecondaryAction>
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}

export default FieldHidder