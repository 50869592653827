import { CardActionArea, MenuItem, Paper, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import React from 'react';
import { NoImage } from '../../..';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';

const Image = () => {
    const { activeElement } = React.useContext(EditorContext)

    return (
        <BaseConfig title={'Image'} subtitle={'Médias'} debug>
            <ImageChoix />
            {activeElement.props.src && <React.Fragment>
                <Aspect />
            </React.Fragment>}
        </BaseConfig>
    )
}

const ImageChoix = () => {
    const { activeElement, dispatch, fileSelectorDispatch, files } = React.useContext(EditorContext)
    const handleOpen = React.useMemo(() => () => {
        fileSelectorDispatch.open({
            title: 'Choisir une image',
            active: activeElement.props.src ?? null,
            list: files.filter(({ mime }: { mime: string }) => mime.startsWith('image')),
            onSelect: ({ id: src }: any) => {
                fileSelectorDispatch.close()
                dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, src } })
            },
            onClose: () => fileSelectorDispatch.close()
        })
    }, [activeElement])


    return (
        <CardActionArea
            onClick={handleOpen}
        >
            <Tooltip title="Cliquer pour choisir une image">
                {activeElement.props.src
                    ? <img style={{ width: '100%', ...activeElement.props }} src={`/download/${activeElement.props.src}`} alt="" />
                    : <Paper sx={{
                        minHeight: 200,
                        height: '100%',
                        ...(activeElement.props.src ? {
                            ...activeElement.props,
                            src: undefined,
                            backgroundImage: activeElement.props.src ? `url("/download/${activeElement.props.src}")` : undefined,
                            minHeight: 'unset',
                            height: 'unset'
                        } : {})
                    }}>
                        {!activeElement.props.src && <NoImage />}
                    </Paper>}
            </Tooltip>
        </CardActionArea>
    )
}

const Aspect = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = ({ target: { value: aspectRatio } }: SelectChangeEvent<HTMLInputElement>) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, aspectRatio: aspectRatio === 'auto' ? undefined : aspectRatio } })
    }
    return (
        <BaseForm title={'Aspect de l\'image'}>
            <Select
                value={activeElement.props.aspectRatio ?? ''}
                onChange={handleChange}
                MenuProps={{
                    sx: { zIndex: 20000 }
                }}>
                <MenuItem value="16/9">16/9</MenuItem>
                <MenuItem value="4/3">4/3</MenuItem>
                <MenuItem value="unset">Auto.</MenuItem>
            </Select>
        </BaseForm>
    )
}

export default Image