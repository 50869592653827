import { Add } from '@mui/icons-material';
import { Button, Divider, FormControlLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField } from '@mui/material';
import React from 'react';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';
import { ListItemConfig } from '../Elements';

const ListContainer = () => {

    const { dispatch } = React.useContext(EditorContext)

    const handleAddEl = () => dispatch.addElement(ListItemConfig, false)

    return (
        <BaseConfig title={'Liste'} subtitle={'Conteneurs'}>
            <Type />
            <Dense />
            <Divider />
            <SubHeader />
            <Button variant='outlined' onClick={handleAddEl}>
                <Add /> Element de liste
            </Button>
        </BaseConfig>
    )
}


const Type = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = ({ target: { value } }: SelectChangeEvent<HTMLInputElement>) => {
        typeof value === 'string' && dispatch.updateElement({ ...activeElement, listType: value as string, childs: activeElement.childs.map(item => ({ ...item, listType: value as string })) })
    }
    return (
        <BaseForm title={'Type de liste'}>
            <Select
                value={activeElement.listType}
                onChange={handleChange}
                MenuProps={{
                    sx: { zIndex: 20000 }
                }}>
                <MenuItem value="ul">Liste à puce</MenuItem>
                <MenuItem value="ol">Liste numérotée</MenuItem>
                <MenuItem value="mui">Liste stylisée</MenuItem>
            </Select>
        </BaseForm>
    )
}


const Dense = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = (_: any, dense: boolean) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, dense } })
    }
    return (
        <BaseForm>
            <FormControlLabel control={<Switch
                checked={activeElement.props.dense}
                onChange={handleChange}
            />} label="Dense" />
        </BaseForm>
    )
}


const SubHeader = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = (_: any, subheader: boolean) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, subheader: subheader === true ? '' : undefined } })
    }
    return (
        <BaseForm>
            <FormControlLabel control={<Switch
                checked={activeElement.props.subheader !== undefined}
                onChange={handleChange}
            />} label="Titre" />
            {activeElement.props.subheader !== undefined &&
                <TextField
                    fullWidth
                    value={activeElement.props.subheader}
                    onChange={({ target: { value: subheader } }) => dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, subheader } })}
                />}
        </BaseForm>
    )
}




export default ListContainer