import type { LineButton } from '../../type'

import React from 'react'

import { TableCell, Box } from '@mui/material'

import { DeleteButton } from '../../..'

import { TableurContext } from '../../useTableur'

import EditButton from './EditButton'
import NavButton from './NavButton'
import ButtonFunction from './ButtonFunction'

interface RowButtonsProps {
    data: any
}

const RowButtons = ({ data }: RowButtonsProps) => {
    const context = React.useContext(TableurContext)
    const { rowButtons } = context!
    const dataFunctionOptions = { data, field: '', value: '', ...context! }

    return (
        <TableCell sx={{ width: '1px', position: 'sticky', right: 0, background: theme => theme.palette.background.paper }} >
            <Box className={'hasButton'} display={'flex'} flexDirection={'row'} gap={2} justifyContent={'flex-end'}>
                {rowButtons?.map((b, i) => <Box key={i}>
                    {{
                        'edit': <EditButton url={'url' in b ? b.url : ''} data={data} urlFn={'urlFn' in b ? b.urlFn : undefined} id={data.id} label={b.label} />,
                        'delete':
                            <DeleteButton
                                onDelete={'onClick' in b ? () => b.onClick(data)! : () => { }}
                                size='small'
                                tooltipTitle={b.label}
                                disabled={'disabled' in b ? (b as any).disabled(dataFunctionOptions) : undefined}
                            />,
                        'function': <ButtonFunction data={data} button={b as LineButton<any> & { type: 'function' }} />,
                        'nav': <NavButton button={b as LineButton<any> & { type: 'nav' }} dataFunctionOptions={dataFunctionOptions} />,
                    }[b.type]}
                </Box>)}
            </Box>
        </TableCell>
    )
}

export default RowButtons