import MainContainer from "./Ui/MainContainer"
import { TableurProps } from "./type"
import useTableur, { TableurContext } from "./useTableur"

function Tableur<T>(props: TableurProps<T>) {
    const context = useTableur(props)
    return (
        <TableurContext.Provider value={context}>
            <MainContainer></MainContainer>
        </TableurContext.Provider>
    )
}

export default Tableur