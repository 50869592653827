import { TextField } from '@mui/material';
import React from 'react';
import { ListItemConfig } from '../Elements';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';

const ListItem = () => {

    return (
        <BaseConfig title={ListItemConfig.label} subtitle={'Conteneurs'}>
            <Text />
        </BaseConfig>
    )
}

const Text = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    return (
        <BaseForm title="Texte">
            <TextField
                fullWidth
                multiline
                label="Principal"
                value={activeElement.props.primary}
                onChange={({ target: { value: primary } }) => dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, primary } })}
                sx={{ mt: 2 }}
            />
            <TextField
                fullWidth
                multiline
                label="Secondaire"
                value={activeElement.props.secondary}
                onChange={({ target: { value: secondary } }) => dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, secondary } })}
                sx={{ mt: 2 }}
            />
        </BaseForm>
    )
}

export default ListItem