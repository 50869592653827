import React from 'react'

interface DateCellProps {
    column: any
    data: any
}
const DateCell = ({ column, data }: DateCellProps) => {
    const field = column.field
    const value = data[field] && !isNaN(new Date(data[field]).getTime()) ? new Date(data[field]) : undefined;
    const locale = column.date?.locale ?? 'fr-FR'

    return (
        <React.Fragment>
            {value
                ? new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(value))
                : ''}
        </React.Fragment>
    )
}

export default DateCell