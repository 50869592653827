import { Grid } from "@mui/material"
import { ComponentWithElementProps } from "../../../types"
import BaseElement from "../BaseElement"
import { StepContainerConfig } from "./config"
import ElementSwitcher from "../ElementSwitcher"


export const StepContainer = ({ element }: ComponentWithElementProps) => {
    return (
        <BaseElement
            element={element}
            configObject={StepContainerConfig}
            dndIds={element.childs.map(child => child.id)}>
            <Grid container spacing={2} direction={'column'}>
                {element.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
            </Grid>
        </BaseElement>
    )
}