import { EditorElement } from "../../../types"
import { CarouselConfig } from "./config"
import CarouselEL from "../../../../../commons/Carousel"
import BaseElement from "../BaseElement"
import { Typography } from "@mui/material"

interface CarouselProps {
    element: EditorElement
}
export const Carousel = ({ element }: CarouselProps) => {

    return (
        <BaseElement element={element} configObject={CarouselConfig}>
            {!element.childs || element.childs.length === 0
                ? <Typography variant="caption" color="GrayText">Aucune image.</Typography>
                : <CarouselEL
                    images={element.childs.map(child => child.props.src) ?? []}
                    stepper={element.props.stepperVariant}
                    autoPlay={element.props.autoPlay}
                />}

        </BaseElement>
    )
}