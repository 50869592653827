import React from 'react'
import { StepContainerConfig } from '../Elements/StepContainer'
import { StepItemConfig } from '../Elements/StepItem'
import BaseConfig, { BaseForm } from './BaseConfig'
import { FormArea } from '../../..';
import Dnd from '../../../../commons/Dnd'
import { EditorContext } from '../../useEditor'
import { ComponentWithElementProps } from '../../types'
import { DragHandle } from '@mui/icons-material'
import { Box } from '@mui/material'
import { CSS } from '@dnd-kit/utilities'
import { arrayMove, useSortable } from '@dnd-kit/sortable'

const SteperContainer = () => {

    return (
        <BaseConfig
            title={StepContainerConfig.label}
            subtitle={'Widgets'}
            adder={[StepItemConfig]}
        >
            <StepOrganizer />
        </BaseConfig>

    )
}

const StepOrganizer = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)

    const onDragEnd = (oldIndex: number, newIndex: number) => dispatch.updateElement({ ...activeElement, childs: arrayMove(activeElement.childs, oldIndex, newIndex) })
    return (
        <BaseForm title='Ordre des étapes'>
            <FormArea>
                <Dnd ids={activeElement.childs.map(child => child.id) ?? []} onDragEnd={onDragEnd}>
                    {activeElement.childs.map((child, index) => <StepRep key={child.id} element={child} index={index} />)}
                </Dnd>
            </FormArea>
        </BaseForm>
    )
}


const StepRep = ({ element, index }: ComponentWithElementProps & { index: number }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: element.id })
    const style = {
        transform: CSS.Transform.toString(transform) as any,
        transition: transition as any,
    };
    return (
        <Box display="flex" gap={1} ref={setNodeRef}
            style={style} {...attributes}>
            <Box>
                <DragHandle {...listeners} />
            </Box>
            <Box flexGrow={1}>
                Etape {index + 1}
            </Box>
        </Box>
    )
}

export default SteperContainer