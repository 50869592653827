import { Edit, ViewQuilt } from '@mui/icons-material'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import React from 'react'
import { EditorContext } from '../../useEditor'

const ToggleEditorViewer = () => {
    const { ui } = React.useContext(EditorContext)

    const handleClick = (_: any, value: null | 'viewer') => {
        const bool = value === 'viewer' ? false : true
        if (ui.editor !== bool) ui.toggleEditor()
    }
    return (
        <ToggleButtonGroup
            value={ui.editor ? 'editor' : 'viewer'}
            // onChange={ui.toggleEditor}
            onChange={handleClick}
            aria-label="screen size"
            size="small"
            exclusive
        >
            <ToggleButton value="editor" aria-label="editor">
                <Edit />
            </ToggleButton>
            <ToggleButton value="viewer" aria-label="viewer">
                <ViewQuilt />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export default ToggleEditorViewer