import type { DocumentExposeType } from "../../AppAdmin/types"
import type { EditorValue } from "./types"

import useEditor, { EditorContext } from "./useEditor"

import { FormArea } from ".."
import MainFrame from "./MainFrame"
import Toolbar from "./Editor/Toolbar/Toolbar"

interface ContentEditorProps {
    value: EditorValue | null
    onSave: (value: EditorValue) => void
    fileSelectorDispatch: any
    files: any[]
    documents: DocumentExposeType[]
}
const ContentEditor = ({ value, onSave, fileSelectorDispatch, files, documents }: ContentEditorProps) => {
    const [contextValue] = useEditor(value, onSave, fileSelectorDispatch, files, documents)
    return (
        <EditorContext.Provider value={contextValue}>
            <FormArea className={contextValue.ui.fullScreen ? 'fullScreen' : undefined}
                sx={{
                    userSelect: 'none',
                    height: '100%',
                    padding: 0,
                    '&:active': {},
                    '&.fullScreen': {
                        position: 'absolute',
                        top: -1, left: -1,
                        bottom: -1, right: -1,
                        zIndex: 10000,
                        height: 'unset',
                        '& [role="innerBox"], & [role="toolbarContainer"], & [role="mainFrameContainer"]': {
                            borderRadius: 0
                        }
                    }
                }}
                innerSx={{
                    margin: 0,
                    display: 'flex',
                    borderTopLeftRadius: contextValue.theme.spacing(.5),
                    borderTopRightRadius: contextValue.theme.spacing(.5),
                    borderBottomLeftRadius: contextValue.theme.spacing(.5),
                    borderBottomRightRadius: contextValue.theme.spacing(.5),
                    color: contextValue.theme.palette.text.primary,
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Toolbar />
                <MainFrame />
            </FormArea>
        </EditorContext.Provider>
    )
}

export default ContentEditor