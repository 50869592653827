import React from 'react'

import { TableCell, Box } from '@mui/material'

import { DeleteButton } from '../../..'

import { TableurContext } from '../../useTableur'

import ExportButton from './ExportButton'
import FieldHidder from './FieldHidder'

const HeadButtons = () => {
    const { headButtons, checked, canHide, checkedButtons, datas } = React.useContext(TableurContext)
    return (
        <TableCell
            sx={{
                width: '1px',
                position: 'sticky',
                right: 0,
                background: theme => theme.palette.background.default,
                textAlign: 'right'
            }}
        >
            <Box className={'hasButton'} display={'flex'} flexDirection={'row'} gap={2} justifyContent={'flex-end'}>
                {headButtons?.map((b, i) => <Box key={i}>
                    {b === 'export'
                        ? <ExportButton key={i} />
                        : <Box key={i}>{b}</Box>}
                </Box>)}
                {checked && checked.length > 0 && checkedButtons &&
                    <DeleteButton
                        onDelete={() => checkedButtons.onClick(datas.filter(d => checked.includes(d.id)))}
                        size='small'
                        tooltipTitle={checkedButtons.label}
                    />}
                {canHide && datas.length > 0 && <FieldHidder />}
            </Box>
        </TableCell>
    )
}

export default HeadButtons