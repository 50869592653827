import React from 'react'

import { Box, IconButton } from '@mui/material'
import { Close, Search } from '@mui/icons-material'

import { TableurContext } from '../useTableur'
import { Input } from '../..'

const SearchField = () => {
    const { activeTab, search, dispatch, translation } = React.useContext(TableurContext)
    const handleSearch = (value: string) => dispatch.setSearch(prev => ({ ...prev, [activeTab]: value }))

    return (
        <Box flexGrow={1} p={1} sx={{ backgroundColor: theme => theme.palette.background.paper }}>
            <Input
                value={search}
                onChange={({ target: { value } }) => handleSearch(value)}
                label={translation?.search ?? 'Votre recherche'}
                InputProps={{
                    startAdornment: <Box sx={{ mr: 1, mb: -3 }}><Search fontSize={'small'} /></Box>,
                    endAdornment: search && <Box sx={{ ml: 1 }} display={'flex'} gap={1}>
                        <IconButton size={'small'} onClick={() => handleSearch('')}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Box>
                }}
                fullWidth />
        </Box>
    )
}

export default SearchField