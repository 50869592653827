import { Save, AccessTime } from '@mui/icons-material'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import React from 'react'
import { EditorContext } from '../../useEditor'

const SaveAutoSave = () => {

    const { save } = React.useContext(EditorContext)

    const handleClickSave = (_: any, value: string[]) => {
        if (value.includes('save')) {
            save.save()
        } else {
            save.toggleAuto()
        }
    }
    return (
        <ToggleButtonGroup
            value={[save.auto ? 'toggleAuto' : '']}
            onChange={handleClickSave}
            aria-label="screen size"
            size="small"
        >
            <ToggleButton value="save" aria-label="enter fullScreen" color="standard">
                <Save />
            </ToggleButton>
            <ToggleButton value="toggleAuto" aria-label="exit fullScreen">
                <AccessTime />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export default SaveAutoSave