import { Box } from "@mui/material"
import React from "react"
import { EditorContext } from "../../useEditor"
import AddConfigElement from "./AddConfigElement"
import SaveAutoSave from "./SaveAutoSave"
import ScreenSize from "./ScreenSize"
import ToggleEditorViewer from "./ToggleEditorViewer"

const Toolbar = () => {
    const { theme } = React.useContext(EditorContext)
    return (
        <Box
            role="toolbarContainer"
            sx={{
                borderTopLeftRadius: theme.spacing(.4),
                borderTopRightRadius: theme.spacing(.4),
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`,
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey['A100'] : theme.palette.grey['A700'],
            }}
            display={'flex'}
            flexWrap={'nowrap'}
            p={1}
        >
            <ScreenSize />

            <Box mr={2} />

            <ToggleEditorViewer />

            <Box flexGrow={1} />

            <SaveAutoSave />

            <Box flexGrow={1} />

            <AddConfigElement />

        </Box>
    )
}

export default Toolbar