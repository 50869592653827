import type { Column } from '../../type'

import { SxProps, Theme } from '@mui/material'
import { Check, Close, Help } from '@mui/icons-material'

interface BoolCellProps {
    column: Column<any>
    data: any
}
const BoolCell = ({ data, column: c }: BoolCellProps) => {
    if (!c.bool) return <></>

    const { TrueIcon, sxTrue } = typeof c.bool === 'boolean'
        ? ({ TrueIcon: Check, sxTrue: { color: theme => theme.palette.primary.main } as SxProps<Theme> })
        : typeof c.bool.true === 'object' && 'element' in c.bool.true
            ? ({ TrueIcon: c.bool.true.element, sxTrue: c.bool.true.sx ?? {} })
            : ({ TrueIcon: c.bool.true, sxTrue: { color: theme => theme.palette.primary.main } as SxProps<Theme> })

    const { FalseIcon, sxFalse } = typeof c.bool === 'boolean'
        ? ({ FalseIcon: Close, sxFalse: { color: theme => theme.palette.secondary.main } as SxProps<Theme> })
        : typeof c.bool.false === 'object' && 'element' in c.bool.false
            ? ({ FalseIcon: c.bool.false.element, sxFalse: c.bool.false.sx ?? {} })
            : ({ FalseIcon: c.bool.false, sxFalse: { color: theme => theme.palette.secondary.main } as SxProps<Theme> })

    return (<>
        {typeof data[c.field] === 'boolean'
            ? data[c.field]
                ? typeof TrueIcon === 'string' ? TrueIcon : <TrueIcon sx={sxTrue} />
                : typeof FalseIcon === 'string' ? FalseIcon : <FalseIcon sx={sxFalse} />
            : typeof data[c.field] === 'number'
                ? data[c.field] === 1
                    ? typeof TrueIcon === 'string' ? TrueIcon : <TrueIcon sx={sxTrue} />
                    : data[c.field] === 0 ? typeof FalseIcon === 'string' ? FalseIcon : <FalseIcon sx={sxFalse} /> : <Help />
                : <Help />}
    </>
    )
}

export default BoolCell