import React from 'react';
import { TableConfig } from '../Elements';
import BaseConfig, { BaseForm } from './BaseConfig';
import { EditorContext } from '../../useEditor';
import { Box, Button, ButtonGroup, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { DeleteButton, FormArea } from '../../..';
import Dnd from '../../../../commons/Dnd';
import { TableColumn, TableSet } from '../../types';
import { Add, ChevronLeft, ChevronRight, DragHandle } from '@mui/icons-material';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Table = () => {

    return (
        <BaseConfig title={TableConfig.label} subtitle={'Widgets'} >
            <HasHeaderFooter />
            <Colonnes />
            <DataEditor />
        </BaseConfig>
    )
}

const HasHeaderFooter = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)

    const handleChangeHasHeader = (_: any, hasHeader: boolean) => dispatch.updateElement({ ...activeElement, hasHeader })
    const handleChangeHasFooter = (_: any, hasFooter: boolean) => dispatch.updateElement({ ...activeElement, hasFooter })

    return (
        <BaseForm>
            <FormControlLabel control={
                <Switch checked={activeElement.hasHeader} onChange={handleChangeHasHeader} />
            } label="En-tête" />
            <FormControlLabel control={
                <Switch checked={activeElement.hasFooter} onChange={handleChangeHasFooter} />
            } label="Pieds" />
        </BaseForm>
    )
}

const Colonnes = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleAdd = () => dispatch.updateElement({ ...activeElement, column: activeElement.column ? [...activeElement.column, { id: Math.max(...activeElement.column.map(c => c.id)) + 1, value: '' }] : [{ id: 0, value: '' }] })
    const handleDragEnd = (oldIndex: number, newIndex: number) => dispatch.updateElement({ ...activeElement, column: arrayMove(activeElement.column!, oldIndex, newIndex) })
    return (
        <BaseForm title='Liste des colonnes' collapsable>
            <FormArea>

                {(!activeElement.column || activeElement.column.length === 0)
                    ? <Typography variant='caption' color='GrayText'>Aucune colonne.</Typography>
                    : <Dnd ids={activeElement.column.map(col => col.id.toString())} onDragEnd={handleDragEnd}>
                        {activeElement.column.map(col => <ColumnConfigSortableItem key={col.id} column={col} />)}
                    </Dnd>
                }

            </FormArea>
            <Button variant='outlined' onClick={handleAdd} sx={{ mt: 1 }}><Add />  Ajouter une colonne</Button>
        </BaseForm>
    )
}

interface ColumnConfigSortableItemProps {
    column: TableColumn
}
const ColumnConfigSortableItem = ({ column }: ColumnConfigSortableItemProps) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: column.id.toString() })
    const style = {
        transform: CSS.Transform.toString(transform) as any,
        transition: transition as any,
    };
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => dispatch.updateElement({ ...activeElement, column: activeElement.column!.map(c => c.id === column.id ? ({ ...column, value }) : c) })
    const handleDelete = () => dispatch.updateElement({ ...activeElement, column: activeElement.column!.filter(c => c.id !== column.id) })
    return (
        <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} gap={1} alignItems={'center'} sx={{ '& .hoverable': { visibility: 'hidden' }, '&:hover .hoverable': { visibility: 'visible' }, mb: 1 }} ref={setNodeRef} style={style} {...attributes} >
            <Box className="hoverable">
                <DragHandle {...listeners} />
            </Box>
            <Box flexGrow={1}>
                <TextField
                    label="Nom de la colonne"
                    fullWidth
                    value={column.value}
                    onChange={handleChange} />
            </Box>
            <Box className="hoverable">
                <DeleteButton onDelete={handleDelete} />
            </Box>
        </Box>
    )
}

const DataEditor = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const [activeId, setActiveId] = React.useState<number | null>(activeElement.tableSets && activeElement.tableSets.length > 0 ? activeElement.tableSets[0].id : null)

    const activeDataSet = activeElement.tableSets?.find(t => t.id === activeId) ?? null
    const activeIndex = activeDataSet ? activeElement.tableSets?.findIndex(s => s.id === activeDataSet.id) : undefined

    const handleAdd = () => {
        let newSet: TableSet = { id: Math.max(...activeElement.tableSets?.map(s => s.id) ?? [-1]) + 1 }
        activeElement.column!.forEach(col => newSet[col.id] = '')

        dispatch.updateElement({ ...activeElement, tableSets: [...activeElement.tableSets ?? [], newSet] })
        setActiveId(newSet.id)

    }

    const handleChangeDataSet = (field: number, value: string) => {
        dispatch.updateElement({ ...activeElement, tableSets: activeElement.tableSets!.map(t => t.id === activeDataSet!.id ? ({ ...t, [field]: value }) : t) })
    }

    const handlePrev = () => {
        if (activeIndex === undefined) return
        setActiveId(activeElement!.tableSets?.at(activeIndex - 1)?.id ?? null)
    }
    const handleNext = () => {
        if (activeIndex === undefined) return
        setActiveId(activeElement!.tableSets?.at(activeIndex + 1)?.id ?? null)
    }

    const handleDeleteDataSet = () => {
        if (activeDataSet === null) return
        dispatch.updateElement({ ...activeElement, tableSets: activeElement!.tableSets!.filter(s => s.id !== activeDataSet.id) })
        setActiveId((activeElement?.tableSets && activeElement.tableSets.at(0)?.id) ?? null)
    }

    const sortItem = (mod: number) => dispatch.updateElement({ ...activeElement, tableSets: arrayMove(activeElement.tableSets!, activeIndex!, activeIndex! + mod) })


    return (
        <BaseForm title='Jeux de données' collapsable>
            <FormArea label={activeDataSet ? `Jeu de donnée ${activeElement!.tableSets!.findIndex(s => s.id === activeId) + 1}` : undefined}>
                {!activeElement.tableSets || activeElement.tableSets.length === 0
                    ? <Typography variant='caption' color='GrayText'>Aucun jeu de données.</Typography>
                    : <React.Fragment>
                        {activeElement.column?.map(col =>
                            <TextField
                                fullWidth
                                label={col.value}
                                value={(activeDataSet && activeDataSet[col.id]) ?? ''}
                                onChange={({ target: { value } }) => handleChangeDataSet(col.id, value)}
                                disabled={!activeDataSet}
                                sx={{ mb: 1 }}
                            />)}
                        <Typography variant="caption"></Typography>
                    </React.Fragment>
                }
            </FormArea>
            <Box mt={1} display={'flex'} flexWrap={'nowrap'}>
                <ButtonGroup size='small'>
                    <Button size='small'
                        title='Jeu précédent'
                        onClick={handlePrev}
                        disabled={!activeElement.tableSets || !activeDataSet || activeElement.tableSets.length < 1 || activeElement.tableSets.findIndex(s => s.id === activeDataSet.id) === 0}>
                        <ChevronLeft fontSize='small' />
                    </Button>
                    <Button size='small'
                        title='Jeu suivant'
                        onClick={handleNext}
                        disabled={!activeElement.tableSets || !activeDataSet || activeElement.tableSets.length < 1 || activeElement.tableSets.findIndex(s => s.id === activeDataSet.id) === (activeElement.tableSets.length - 1)}>
                        <ChevronRight fontSize='small' />
                    </Button>
                </ButtonGroup>
                <Box flexGrow={1} />
                <DeleteButton rounded={false} onDelete={handleDeleteDataSet} label="" size="small" />
                <Box flexGrow={1} />
                <ButtonGroup size='small'>
                    <Button size='small'
                        title='Jeu précédent'
                        onClick={() => sortItem(- 1)}
                        disabled={!activeDataSet || !activeIndex}>
                        <ChevronLeft fontSize='small' sx={{ transform: 'rotate(90deg)' }} />
                    </Button>
                    <Button size='small'
                        title='Jeu suivant'
                        onClick={() => sortItem(1)}
                        disabled={!activeDataSet || !activeIndex || activeIndex === (activeElement!.tableSets!.length - 1)}>
                        <ChevronRight fontSize='small' sx={{ transform: 'rotate(90deg)' }} />
                    </Button>
                </ButtonGroup>
            </Box>
            <Button variant='outlined' onClick={handleAdd} sx={{ mt: 1 }}><Add />  Ajouter un jeu</Button>
        </BaseForm>
    )
}


export default Table