import { TabPanel, TabContext } from '@mui/lab'
import { Grid, Card, CardContent } from '@mui/material'
import React from 'react'
import { Adder } from './Adder'
import ConfigSwitcher from './Configs/ConfigSwitcher'
import ElementSwitcher from './Elements/ElementSwitcher'
import { EditorContext } from '../useEditor'

interface EditorPanelProps {
    debug?: boolean
}
const EditorPanel = ({ debug }: EditorPanelProps) => {
    const { tabs, theme, value, activeElement } = React.useContext(EditorContext)
    return (
        <TabPanel value="edit" sx={{ margin: 0, padding: 0, height: '100%' }}>


            <Grid container sx={{ height: '100%', alignItems: 'stretch', overflow: 'hidden' }}>
                <Grid item xs={9}
                    sx={{
                        height: '100%',
                        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[800],
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                    p={.5}
                >
                    <Card sx={{ minHeight: '100%' }}>
                        <CardContent>
                            <ElementSwitcher element={value} />
                            {debug && <>
                                <p>value: {JSON.stringify(value)}</p>
                                <p>activeElement: {JSON.stringify(activeElement)}</p>
                                <p>activeElementId: {activeElement.id}</p>
                                <p>activeElementType: {activeElement.type}</p>
                            </>}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3} bgcolor={'yellow'}
                    sx={{
                        height: '100%',
                        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[900],
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}>
                    <TabContext value={tabs.value}>
                        <TabPanel value={'config'}><ConfigSwitcher /></TabPanel>
                        <TabPanel value={'add'} sx={{ p: '1px', pt: 0 }}><Adder /></TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export default EditorPanel