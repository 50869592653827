import { Box, type SxProps, Typography } from "@mui/material"
import React from "react"
import ThemeContext from "../theme/ThemeContext"

interface FormAreaProps {
    label?: string | React.ReactNode
    children: JSX.Element | JSX.Element[] | React.ReactNode
    sx?: SxProps
    innerSx?: SxProps
    className?: string
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    inCard?: boolean
}

export const FormArea = ({ label, children, sx = {}, innerSx = { p: .5 }, className, onClick, inCard = true }: FormAreaProps) => {
    const { theme } = React.useContext(ThemeContext)
    return (
        <Box sx={{
            position: 'relative',
            border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`,
            borderRadius: theme.spacing(.5),
            p: 1,
            '&:hover': {
                borderColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff'
            },
            '&:active': {
                borderColor: theme.palette.primary.main,
                '& [role="FormAreaLabel"]': {
                    color: `${theme.palette.primary.main} !important`
                }
            },
            ...sx
        }} className={className} onClick={onClick}>
            {label &&
                <Typography
                    variant="caption"
                    role="FormAreaLabel"
                    sx={{
                        top: 0,
                        position: 'absolute',
                        pl: 0.5,
                        pr: 1,
                        color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
                        backgroundColor: theme.palette.mode === 'light'
                            ? inCard ? theme.palette.background.paper : '#fafafa'
                            : '#424242',
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                        transform: 'translate(0px, -10px)',
                        userSelect: 'none'
                    }}>
                    {label}
                </Typography>}
            <Box role={'innerBox'} sx={innerSx}>
                {children}
            </Box>
        </Box>

    )
}