import { Grid } from "@mui/material"
import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import ElementSwitcher from "../ElementSwitcher"
import { GridItemConfig } from "./config"

interface GridItemProps {
    element: EditorElement
}
export const GridItem = ({ element }: GridItemProps) => {
    return (
        <BaseElement element={element} configObject={GridItemConfig} dndIds={element.childs.map(child => child.id)} {...element.props}>
            <Grid container direction={'column'} >
                {element.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
            </Grid>
        </BaseElement>

    )
}