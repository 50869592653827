import { Box, Button, ButtonGroup, Collapse, Divider, FormControl, FormLabel, IconButton, Paper, SxProps, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import Tuile from '../Adder/Tuile'
import { EditorContext } from '../../useEditor'
import { ChevronLeftRounded, ContentCopy, ContentPasteGo } from '@mui/icons-material'
import ThemeContext from '../../../../theme/ThemeContext'
import { AlertConfig, CarouselConfig, ChecklistConfig, ChecklistItemConfig, DividerConfig, GridContainerConfig, GridItemConfig, ImageConfig, ListContainerConfig, ListItemConfig, TableConfig, TexteConfig, TitreConfig, VideoConfig } from '../Elements'
import { OngletContainerConfig } from '../Elements/OngletContainer'
import { OngletItemConfig } from '../Elements/OngletItem'
import { StepContainerConfig } from '../Elements/StepContainer'
import { StepItemConfig } from '../Elements/StepItem'
import { DocumentLinkConfig, ExternalLinkConfig } from '../Elements/Links'
import { DeleteButton } from '../../..'

interface BaseConfigProps {
    title: string,
    subtitle: string
    adder?: any[]
    debug?: boolean
}

const BaseConfig = ({ title, subtitle, children, adder, debug }: BaseConfigProps & PropsWithChildren) => {
    const { activeElement, copyedElement, dispatch } = React.useContext(EditorContext)
    const { theme } = React.useContext(ThemeContext)
    const handleDelete = () => dispatch.deleteElement()

    const configs = [
        AlertConfig, CarouselConfig, ChecklistConfig, ChecklistItemConfig, DividerConfig, GridContainerConfig, GridItemConfig, ImageConfig, VideoConfig, ListContainerConfig, ListItemConfig, TableConfig, TitreConfig, TexteConfig,
        OngletContainerConfig, OngletItemConfig, StepContainerConfig, StepItemConfig, DocumentLinkConfig, ExternalLinkConfig
    ]

    const copyedElementConfig = (copyedElement && configs.find(c => c.type === copyedElement.type)) ?? null
    const allowCopy = copyedElementConfig && copyedElementConfig.allowedBy.includes(activeElement.type)

    return (
        <Box
            key={activeElement.id}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
        >
            <Box>
                <Typography variant='h4'>{title}</Typography>
                <Typography variant='caption'>{subtitle}</Typography>
                <Divider />
            </Box>
            <ButtonGroup size='small'>
                <Button sx={{ backgroundColor: theme.palette.background.paper }} disabled={!activeElement} onClick={dispatch.copyElement} title="Copier l'élément">
                    <ContentCopy fontSize='small' />
                </Button>
                <Button sx={{ backgroundColor: theme.palette.background.paper }} disabled={!copyedElement || !allowCopy || copyedElement.id === activeElement.id} onClick={dispatch.pasteElement} title="Coller">
                    <ContentPasteGo fontSize='small' />
                </Button>
            </ButtonGroup>
            {children}
            {adder &&
                <React.Fragment>
                    <Box>
                        <Typography>
                            Ajouter
                        </Typography>
                        <Divider />
                    </Box>
                    <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                        {adder.map((config, index) => <Tuile key={index} item={{ ...config, autoConfig: false }} />)}
                    </Box>
                </React.Fragment>}
            {(children || adder) && <Box>
                <Divider />
            </Box>}
            <Box>
                <DeleteButton onDelete={handleDelete} rounded={false} />
            </Box>
            {debug && <BaseForm title={'debug'}><code style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(activeElement).replaceAll(',"', ', "')}</code></BaseForm>}
        </Box>
    )
}

interface BaseFormProps {
    title?: string
    sx?: SxProps
    collapseSx?: SxProps
    collapsable?: boolean
}
export const BaseForm = ({ children, title, sx, collapsable, collapseSx }: BaseFormProps & PropsWithChildren) => {
    const [open, toggleOpen] = React.useReducer((o) => !o, true)
    return (
        <Box sx={sx}>
            <Paper sx={{ p: 2, height: '100%' }}>
                <FormControl
                    sx={{ width: '100%' }}
                >
                    {title && <FormLabel component="legend">
                        <Box display={'flex'} alignItems={'center'}>

                            {collapsable && <IconButton onClick={toggleOpen}><ChevronLeftRounded sx={{ transform: open ? 'rotate(-90deg)' : 'rotate(180deg)' }} /></IconButton>}{title}
                        </Box>
                    </FormLabel>}
                    {collapsable
                        ? <Collapse in={open}>
                            <Box sx={collapseSx}>
                                {children}
                            </Box>
                        </Collapse>
                        : children}

                </FormControl>
            </Paper>
        </Box>

    )
}


export default BaseConfig