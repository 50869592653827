import { TextField } from '@mui/material';
import React from 'react';
import { ChecklistConfig, ChecklistItemConfig } from '../Elements';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';

const CheckList = () => {
    return (
        <BaseConfig
            title={ChecklistConfig.label}
            subtitle={'Widget'}
            adder={[ChecklistItemConfig]}>
            <Titre />
        </BaseConfig>
    )
}

const Titre = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = ({ target: { value: titre } }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch.updateElement({ ...activeElement, titre })
    }
    return (
        <BaseForm>
            <TextField
                label="Titre de la checkliste"
                fullWidth
                value={activeElement.titre ?? ''}
                onChange={handleChange}
            />
        </BaseForm>
    )
}

export default CheckList