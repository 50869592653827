import { TableBody } from '@mui/material'
import React from 'react'
import { TableurContext } from '../useTableur'
import Row from './Row'
import NoDatas from './NoDatas'
import Dnd from '../../../commons/Dnd'

const Body = () => {
    const { datas, paginationObject: { from, to, nbPerPage }, sortEnd } = React.useContext(TableurContext)
    return (
        <Dnd
            ids={datas.map(d => d.id)}
            onDragEnd={sortEnd ?? (() => { })}
        >
            <TableBody
                sx={{
                    '& tr:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                }}>
                {(nbPerPage === -1 ? datas : datas.slice(from, to + 1)).map((d) => <Row key={d.id} data={d} />)}
                {datas.length === 0 && <NoDatas />}
            </TableBody>
        </Dnd>
    )
}

export default Body