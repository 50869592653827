import { TabPanel } from '@mui/lab'
import { Box, Card, CardContent, Container } from '@mui/material'
import React from 'react'
import { EditorContext } from '../useEditor'
import ElementSwitcher from './ElementSwitcher'

interface ViewerPanelProps {
    debug?: boolean
}
const ViewerPanel = ({ debug }: ViewerPanelProps) => {
    const { theme, value } = React.useContext(EditorContext)
    return (
        <TabPanel value="view" sx={{ margin: 0, padding: 0, height: '100%' }}>
            <Box
                sx={{
                    height: '100%',
                    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[800],
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
                p={.5}
            >
                <Card sx={{ minHeight: '100%', p: 2 }}>
                    <CardContent>
                        <Container>

                            <ElementSwitcher element={value} />
                        </Container>
                    </CardContent>
                </Card>
            </Box>


        </TabPanel>
    )
}

export default ViewerPanel