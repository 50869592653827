import React from 'react'

import { Box, IconButton, Typography } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

import { TableurContext } from '../../useTableur'
import ButtonPerPage from './ButtonPerPage'

const PaginationContainer = () => {
    const { activeTab, paginationObject: { from, to, page, nbPerPage, length, nbPage }, dispatch: { setPage } } = React.useContext(TableurContext)

    const handlePrev = () => setPage(prev => ({
        ...prev,
        [activeTab]: page > 1 ? page - 1 : page
    }))

    const handleNext = () => setPage(prev => ({
        ...prev,
        [activeTab]: page < nbPage ? page + 1 : page
    }))

    return (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2} p={2} sx={{ backgroundColor: (t) => t.palette.background.default }} >
            <Box sx={{ mr: 'auto' }}></Box>
            <Box>
                <ButtonPerPage />
            </Box>
            {to !== Infinity && <Box>
                <Typography variant="caption">
                    {from + 1}-{to + 1} ({length})
                </Typography>
            </Box>}
            <Box>
                <IconButton size={'small'} disabled={nbPerPage === -1 || page === 1} onClick={handlePrev}>
                    <ChevronLeft fontSize={'small'} />
                </IconButton>
            </Box>
            <Box>
                <IconButton size={'small'} disabled={nbPerPage === -1 || page === nbPage} onClick={handleNext}>
                    <ChevronRight fontSize={'small'} />
                </IconButton>
            </Box>
            <Box sx={{ ml: 'auto' }}></Box>
        </Box>
    )
}

export default PaginationContainer