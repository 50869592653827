import React from "react"
import { SortType, useTableurContextType, useTableurDispatchType, useTableurType } from "./type"
import type { PaginationObject } from './type'

export const TableurContext = React.createContext<useTableurContextType<any>>({} as useTableurContextType<any>)

const useTableur: useTableurType<any> = (init) => {
    const [activeTab, setActiveTab] = React.useState<'Tout' | string>(init.startTab ?? init.noAll === true ? 'key' in init.tabs![0] ? init.tabs![0].key : init.tabs![0].label : 'Tout')

    const [search, setSearch] = React.useState<{ [k: string]: string }>({})

    const [page, setPage] = React.useState<{ [k: string]: number }>({ [init.startTab ?? 'Tout']: 1 })
    const [nbPerPage, setNbPerPage] = React.useState<{ [k: string]: number }>({ [init.startTab ?? 'Tout']: -1 })
    const [checked, setChecked] = React.useState<{ [key: string]: string[] }>({ [init.startTab ?? 'Tout']: [] })

    const [hiddenFields, setHiddenFields] = React.useState<{ [key: string]: string[] }>({ [init.startTab ?? 'Tout']: [] })

    const [sort, setSort] = React.useState<{ [k: string]: SortType<any> }>({ [init.startTab ?? 'Tout']: init.innitialSort ?? null })

    const tabs = React.useMemo(() => {
        return (
            init.noAll
                ? init.tabs!
                : init.tabs
                    ? [
                        { label: init.allLabel ?? 'Tout' },
                        ...init.tabs!
                    ]
                    : null
        )
    }, [init])

    const tab = React.useMemo(() => {
        if (init.noAll) {
            const el = init.tabs!.find(t => ('key' in t ? t.key : t.label) === activeTab)
            console.log(el)
            return el!
        } else {
            const el = init.tabs?.find(t => ('key' in t ? t.key : t.label) === activeTab)
            return {
                ...el ?? {},
                label: init.startTab ?? 'Tout',
                columns: init.columns,
                datas: init.datas,
                canChecked: init.canChecked,
                checkedButtons: init.checkedButtons,
                rowButtons: init.rowButtons,
                headButtons: init.headButtons,
                pagination: init.pagination,
                dense: init.dense,
                canHide: init.canHide,
                isLoading: init.isLoading,
                collapsable: init.collapsable,
                collapsableKeepMount: init.collapsableKeepMount ?? false,
                collapsableDefaultOpen: init.collapsableDefaultOpen ?? false
            }
        }

    }, [init, activeTab])

    const canChecked = React.useMemo(() => tab.canChecked ?? false, [tab])

    const columns = React.useMemo(() => tab.columns.filter(c => c.disabled !== true), [init, activeTab])

    const hasSearch = React.useMemo(() => columns.filter(c => 'onSearch' in c).length > 0, [columns])

    const hasButtons = React.useMemo(() => tab.canHide ?? [...tab.rowButtons ?? [], ...tab.headButtons ?? []].length > 0, [tab])

    const datas = React.useMemo(() => {
        let finalDatas = tab.datas!
        if (finalDatas.length === 0) return finalDatas

        if ('filter' in tab) {
            finalDatas = tab.filter({ datas: finalDatas, activeTab, tabs, search: search[activeTab], sort: sort[activeTab], columns })
            if (finalDatas.length === 0) return finalDatas
        }

        if (search[activeTab] && hasSearch) {
            const dev = false
            if (!dev) {

                finalDatas = search[activeTab].toLocaleLowerCase().trim().split(' ')
                    .map(searchText => finalDatas.filter((d: any) => columns?.filter(c => 'onSearch' in c)
                        .map(c => c.onSearch!({
                            field: c.field,
                            value: d[c.field],
                            data: d,
                            activeTab,
                            hasSearch,
                            tabs,
                            search: searchText
                        }))
                        .includes(true))
                    ).flat()
            } else {
                const resultats = search[activeTab].toLocaleLowerCase().trim().split(' ')
                    .map(searchText => ({
                        searchText,
                        datas: finalDatas.filter((d: any) => columns?.filter(c => 'onSearch' in c)
                            .map(c => c.onSearch!({
                                field: c.field,
                                value: d[c.field],
                                data: d,
                                activeTab,
                                hasSearch,
                                tabs,
                                search: searchText
                            }))
                            .includes(true)).length
                    }))
                console.log(resultats)
            }
        }

        if (sort[activeTab]) {
            const sorter = columns?.find(c => c.field === sort[activeTab]!.field && 'onSort' in c)?.onSort
            if (sorter) {
                const o = sort[activeTab]!.order! === 'desc' ? -1 : 1
                if (typeof sorter === 'function') {
                    finalDatas = finalDatas.sort((a: any, b: any) => sorter(a[sort[activeTab]!.field], b[sort[activeTab]!.field]) ? o : -o)
                } else {
                    finalDatas = finalDatas.sort((a: any, b: any) => typeof a[sort[activeTab]!.field] === 'string' && typeof b[sort[activeTab]!.field] === 'string'
                        ? a[sort[activeTab]!.field].localeCompare(b[sort[activeTab]!.field], 'fr', { sensitivity: 'base' }) * (-o)
                        : a[sort[activeTab]!.field] < b[sort[activeTab]!.field] ? o : -o)
                }
            }
        }

        return finalDatas
    }, [init, activeTab, columns, search, sort])

    const paginationObject: PaginationObject = React.useMemo(() => ({
        page: page[activeTab] ?? 1,
        nbPerPage: (nbPerPage[activeTab] ?? -1),
        nbPage: Math.ceil((nbPerPage[activeTab] ?? -1) === -1 ? 1 : datas.length / nbPerPage[activeTab]),
        length: datas.length,
        from: (nbPerPage[activeTab] ?? -1) > 0 ? ((page[activeTab] ?? 1) - 1) * nbPerPage[activeTab] : 0,
        to: (nbPerPage[activeTab] ?? -1) > 0 ? (page[activeTab] ?? 1) * nbPerPage[activeTab] - 1 : Infinity,
        options: [5, 10, 20, 50, 100]
    }), [datas, page, nbPerPage])

    const dispatch: useTableurDispatchType = React.useMemo(() => ({
        setActiveTab, setSearch: setSearch, setNbPerPage, setPage, setChecked, setHiddenFields,
        sort: (field) => setSort(prev => ({
            ...prev,
            [activeTab]: (prev[activeTab] ?? { field: null }).field === field
                ? ({ field, order: (prev[activeTab] ?? { order: null }).order === 'asc' ? 'desc' : 'asc' })
                : ({ field, order: 'desc' })
        }))
    }), [activeTab])

    return {
        display: init.display ?? undefined,
        noCard: init.noCard ?? false,
        activeTab,
        hasSearch,
        hasButtons,
        dense: tab.dense ?? false,
        isLoading: tab.isLoading ?? false,
        loading: false,

        columns: columns,
        datas,
        canHide: tab.canHide ?? false,
        hiddenFields: hiddenFields[activeTab] ?? undefined,
        collapsable: tab.collapsable ?? undefined,
        collapsableKeepMount: tab.collapsableKeepMount ?? false,
        collapsableDefaultOpen: tab.collapsableDefaultOpen ?? false,

        tabs,
        search: search[activeTab] ?? '',
        sort: sort[activeTab],
        checked: canChecked ? checked[activeTab] ?? [] : undefined,
        checkedButtons: canChecked ? (init.noAll ? init.tabs!.find(t => ('key' in t ? t.key : t.label) === activeTab && 'checkedButtons' in t)!.checkedButtons : init.checkedButtons) ?? undefined : undefined,

        rowButtons: tab.rowButtons ?? [],
        headButtons: tab.headButtons ?? [],

        paginationObject,
        hasPagination: tab.pagination !== undefined,

        sortEnd: init.sortEnd,
        dispatch,
        translation: init.translation,

        sx: init.sx
    }
}

export default useTableur