import { useState, useCallback } from 'react';

type Identifiable = {
    id: string;
};

type MapState<T extends Identifiable> = {
    state: T[];
    set: (values: T[]) => void;
    get: (id: string) => T | undefined;
    has: (id: string) => boolean;
    remove: (id: string) => void;
    clear: () => void;
    update: (value: Partial<T> & Identifiable) => void;
    updateBulk: (values: (Partial<T> & Identifiable)[]) => void;
};

function useMappedState<T extends Identifiable>(): MapState<T> {
    const [map, setMap] = useState<Map<string, T>>(new Map());

    const set = useCallback((values: T[]) => {
        const newMap = new Map<string, T>();
        values.forEach(value => newMap.set(value.id, value));
        setMap(newMap);
    }, []);

    const get = useCallback((id: string) => {
        return map.get(id);
    }, [map]);

    const has = useCallback((id: string) => {
        return map.has(id);
    }, [map]);

    const remove = useCallback((id: string) => {
        setMap(prevMap => {
            const newMap = new Map(prevMap);
            newMap.delete(id);
            return newMap;
        });
    }, []);

    const clear = useCallback(() => {
        setMap(new Map());
    }, []);

    const update = useCallback((value: Partial<T> & Identifiable) => {
        setMap(prevMap => {
            const newMap = new Map(prevMap);
            const existingValue = newMap.get(value.id);
            const newValue = existingValue ? { ...existingValue, ...value } : (value as T);
            newMap.set(value.id, newValue);
            return newMap;
        });
    }, []);

    const updateBulk = useCallback((values: (Partial<T> & Identifiable)[]) => {
        setMap(prevMap => {
            const newMap = new Map(prevMap);
            values.forEach(value => {
                const existingValue = newMap.get(value.id);
                const newValue = existingValue ? { ...existingValue, ...value } : (value as T);
                newMap.set(value.id, newValue);
            });
            return newMap;
        });
    }, []);

    const state = Array.from(map.values());

    return { state, set, get, has, remove, clear, update, updateBulk };
}

export default useMappedState;
