import React from 'react'

import { TableCell, Checkbox } from '@mui/material'

import { TableurContext } from '../useTableur'

const CellChecker = () => {
    const { activeTab, checked, datas, dispatch: { setChecked } } = React.useContext(TableurContext)

    const handleClick = () => setChecked(prev => ({
        ...prev,
        [activeTab]: checked && checked.length === datas.length ? [] : datas.map(d => d.id)
    }))

    return (
        <TableCell padding="checkbox">
            <Checkbox
                color="primary"
                indeterminate={checked!.length > 0 && checked!.length < datas.length}
                checked={checked!.length > 0 && checked!.length === datas.length}
                onChange={handleClick}
            />
        </TableCell>
    )
}

export default CellChecker