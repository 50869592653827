import { Box, Card, Collapse, Divider, LinearProgress } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { TableurContext } from '../useTableur'
import TabsContainer from './TabsContainer'
import SearchField from './SearchField'
import Table from '../Table/Main'
import PaginationContainer from '../Table/Pagination/PaginationContainer'

const MainContainer = (props: PropsWithChildren) => {
    const { tabs, hasSearch, hasPagination, isLoading, display, sx } = React.useContext(TableurContext)
    return (
        <Box component={Card} variant={'outlined'} sx={{
            position: 'relative',
            display: display ?? 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            overflowY: 'hidden',
            height: '1px',
            ...sx
        }}>
            {tabs && <Box className="ttest" sx={{
                backgroundColor: theme => theme.palette.background.paper,
                zIndex: 500
            }}>
                <Box display="flex" flexDirection={'row'} gap={2}>
                    {tabs && <TabsContainer />}
                </Box>
                <Divider />
            </Box>}
            <Box sx={{ backgroundColor: theme => theme.palette.background.paper }}>
                <Collapse in={hasSearch} sx={{ bgcolor: theme => theme.palette.background.paper }}>
                    <SearchField />
                    <Divider />
                </Collapse>
            </Box>
            {isLoading !== false && <Box>
                <LinearProgress sx={{ zIndex: 2000 }} variant={typeof isLoading === 'number' ? 'determinate' : undefined} value={typeof isLoading === 'number' ? 100 * isLoading : undefined} />
            </Box>}
            <Box flexGrow={1} sx={{ overflowY: 'auto' }}>
                <Table />
                {props.children}
            </Box>
            <Collapse in={Boolean(hasPagination)}>
                <Divider />
                <Box>
                    <PaginationContainer />
                </Box>
            </Collapse>
        </Box >
    )
}

export default MainContainer