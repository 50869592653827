import React from "react"
import { AdderConfig } from "../../types"
import { GridContainerConfig, GridItemConfig, ListContainerConfig, TexteConfig, TitreConfig, DividerConfig, ImageConfig, VideoConfig, AlertConfig, CarouselConfig, ChecklistConfig, TableConfig } from "../Elements"
import Group from "./Group"
import { DocumentLinkConfig, ExternalLinkConfig } from "../Elements/Links"
import { StepContainerConfig } from "../Elements/StepContainer"
import { OngletContainerConfig } from "../Elements/OngletContainer"

const Adder = () => {
    const config: AdderConfig = [
        {
            label: 'Conteneurs', items: [
                GridContainerConfig,
                GridItemConfig,
                ListContainerConfig
            ]
        },
        {
            label: 'Textes', items: [
                TitreConfig,
                TexteConfig,
                DividerConfig
            ]
        },
        {
            label: 'Médias', items: [
                ImageConfig,
                VideoConfig
            ]
        },
        {
            label: 'Widgets', items: [
                AlertConfig,
                CarouselConfig,
                ChecklistConfig,
                OngletContainerConfig,
                StepContainerConfig,
                TableConfig,
            ]
        },
        {
            label: 'Liens', items: [
                DocumentLinkConfig,
                ExternalLinkConfig
            ]
        },
    ]

    return (
        <React.Fragment>
            {config.map((group, index) => <Group key={index} config={group} />)}
        </React.Fragment>
    )
}

export default Adder