import { Typography as MuiTypo } from "@mui/material"
import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import { TitreConfig, TexteConfig } from "./config"

interface TypographyProps {
    element: EditorElement
}
export const Typography = ({ element }: TypographyProps) => {
    return (
        <BaseElement element={element} configObject={element.type === 4 ? TitreConfig : TexteConfig}>
            <MuiTypo {...element.props} gutterBottom={false}>{element.value ?? ''}</MuiTypo>
        </BaseElement>
    )
}

