import React from 'react'

import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material'
import { ChevronRight, DragHandle } from '@mui/icons-material'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { TableurContext } from '../useTableur'
import AvatarCell from './Cells/AvatarCell'
import BoolCell from './Cells/BoolCell'
import ChipCell from './Cells/ChipCell'
import AvatarGroupCell from './Cells/AvatarGroupCell'
import RowButtons from './Buttons/RowButtons'
import RowChecker from './RowChecker'
import DateCell from './Cells/DateCell'

interface RowProps {
    data: any
}
const Row = ({ data }: RowProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: data.id })
    const style = {
        transform: CSS.Transform.toString(transform) as any,
        transition: transition as any,
        zIndex: isDragging ? 1000 : undefined,
    };
    const context = React.useContext(TableurContext)
    const { columns, hasButtons, hiddenFields, checked, sortEnd, collapsable, collapsableKeepMount, collapsableDefaultOpen } = context

    const [open, toggleOpen] = React.useReducer(o => !o, collapsableDefaultOpen ?? false)

    const cellNb = columns.length + (sortEnd ? 1 : 0) + (checked ? 1 : 0) + (collapsable ? 1 : 0) + (hasButtons ? 1 : 0)
    return (
        <React.Fragment>
            <TableRow sx={{
                '& .hasButton': { visibility: 'hidden' },
                '& td:first-of-type [type="checkbox"]+svg': { visibility: 'hidden' },
                '& td:first-of-type [type="checkbox"]:checked+svg': { visibility: 'visible' },
                '&:hover td:first-of-type [type="checkbox"]+svg': { visibility: 'visible' },
                '&:hover .hasButton': { visibility: 'visible' },
                ...(collapsable ? {
                    '& > td': { borderBottom: 'unset' }
                } : {}),
                ...style
            }}
                ref={setNodeRef} {...attributes}
            >
                {sortEnd && <TableCell><Box className={'hasButton'}><DragHandle {...listeners} /></Box></TableCell>}
                {checked && <RowChecker checked={checked && checked.includes(data.id)} id={data.id} />}
                {collapsable && <TableCell><IconButton onClick={toggleOpen}><ChevronRight sx={{ transform: open ? 'rotate(90DEG)' : undefined }} /></IconButton></TableCell>}
                {columns.filter(c => hiddenFields ? !hiddenFields.includes(c.field) : !c.hidden).map((c, i) =>
                    <TableCell key={`${c.field}${i}`} sx={{ ...(c.body?.sx ?? c.header?.sx), ...(c.sticky ? { position: 'sticky', left: 0, background: theme => theme.palette.background.paper, display: 'table-cell' } : {}) }}>
                        {c.avatar
                            ? <AvatarCell key={`${c.field}${i}`} column={c} data={data} />
                            : c.bool
                                ? <BoolCell key={`${c.field}${i}`} column={c} data={data} />
                                : c.chip
                                    ? <ChipCell key={`${c.field}${i}`} column={c} data={data} />
                                    : c.groupAvatar
                                        ? <AvatarGroupCell key={`${c.field}${i}`} column={c} data={data} />
                                        : c.date
                                            ? <DateCell key={`${c.field}${i}`} column={c} data={data} />
                                            : c.body
                                                ? c.body.render({ value: data[c.field], field: c.field, data, ...context })
                                                : data[c.field]}
                    </TableCell>)}
                {/* {hasButtons && <TableCell sx={{ width: '1px', position: 'sticky', right: 0, background: theme => theme.palette.background.paper }} >
                <Box className={'hasButton'} display={'flex'} flexDirection={'row'} gap={2}>
                    {rowButtons?.map((b, i) => <Box key={i}>
                        {{
                            'edit': <EditButton url={'url' in b ? b.url : ''} id={data.id} />,
                            'delete': <DeleteButton onDelete={'onClick' in b ? () => b.onClick(data)! : () => { }} size='small' tooltipTitle={b.label} />,
                            'function': <></>,
                            'nav': <IconButton component={Link} to={'url' in b ? b.url : ''}>{'icon' in b && b.icon !== undefined ? <b.icon fontSize="small" /> : <LinkIcon fontSize={'small'} />}</IconButton>,
                        }[b.type]}
                    </Box>)}
                </Box> */}
                {/* </TableCell>} */}
                {hasButtons && <RowButtons data={data} />}
            </TableRow >
            {collapsable && <TableRow /*style={{ display: open ? undefined : 'none' }}*/>
                <TableCell colSpan={cellNb} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} unmountOnExit={!collapsableKeepMount}>
                        {collapsable({ data, ...context, field: 'collapsable', value: 'null' }, open)}
                    </Collapse>
                </TableCell>
                {/* <TableCell colSpan={columns.length + 1}>
                </TableCell> */}
            </TableRow>}
        </React.Fragment>
    )
}

export default Row