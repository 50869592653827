import React from "react"
import { EditorContext } from "../../useEditor"
import Divider from "./Divider"
import ListItem from "./ListItem"
import GridContainer from "./GridContainer"
import GridItem from "./GridItem"
import ListContainer from "./ListContainer"
import Typography from "./Typography"
import Alert from "./Alert"
import Image from "./Image"
import Video from "./Video"
import CheckList from "./CheckList"
import CheckListItem from "./CheckListItem"
import Carousel from "./Carousel"
import Table from "./Table"
import SteperContainer from "./SteperContainer"
import SteperItem from "./SteperItem"
import OngletContainer from "./OngletContainer"
import OngletItem from "./OngletItem"
import DocumentLink from "./DocumentLink"
import ExternalLink from "./ExternalLink"

const ConfigSwitcher = () => {
    const { activeElement } = React.useContext(EditorContext)

    switch (activeElement.type) {
        case 1: return (<GridContainer />)
        case 2: return (<GridItem />)
        case 3: return (<ListContainer />)
        case 4: case 5: return (<Typography />)
        case 6: return (<Divider />)
        case 7: return (<Image />)
        case 8: return (<Video />)
        case 9: return (<Alert />)
        case 10: return (<Carousel />)
        case 11: return (<CheckList />)
        case 12: return (<ListItem />)
        case 13: return (<CheckListItem />)
        case 14: return (<Table />)
        case 15: return (<DocumentLink />)
        case 16: return (<ExternalLink />)
        case 17: return (<SteperContainer />)
        case 18: return (<SteperItem />)
        case 19: return (<OngletContainer />)
        case 20: return (<OngletItem />)
        default: return (<>not implemented el.type {activeElement.type}</>)
    }
}

export default ConfigSwitcher