import { restrictToParentElement } from '@dnd-kit/modifiers'
import { arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Box, Grid } from '@mui/material'
import React from 'react'
import { EditorElement } from '../../types'
import { EditorContext } from '../../useEditor'
import { FormArea } from '../../..'
import Dnd from '../../../../commons/Dnd'

interface BaseElementProps {
    element: EditorElement
    parentElement?: EditorElement
    configObject: any,
    dndIds?: string[]
}
const BaseElement = ({ element, configObject, children, dndIds, parentElement, ...rest }: BaseElementProps & React.PropsWithChildren) => {
    const { activeElement, dispatch, theme } = React.useContext(EditorContext)
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: element.id })
    const handleSelect = (event: any) => {
        if (event.type === 'click') {
            event.stopPropagation()
            event.preventDefault()
            activeElement.id !== element.id && dispatch.selectElement(element)
        }
    }
    const style = {
        transform: CSS.Transform.toString(transform) as any,
        transition: transition as any,
    };

    const onDragEnd = (oldIndex: number, newIndex: number) => dispatch.updateElement({ ...element, childs: arrayMove(element.childs, oldIndex, newIndex) })

    return (
        <Grid item xs={true} mb={element.props.gutterBottom ? 4 : 2} ref={setNodeRef} style={style} {...attributes} {...listeners} {...rest}>
            <Box sx={{ width: '100%' }}>
                <FormArea
                    className={activeElement.id === element.id ? 'active' : undefined}
                    label={configObject.label}
                    onClick={handleSelect}
                    sx={{
                        '& .MuiAlert-message': { width: '100%', maxWidth: '100%' },
                        '&:active': {},
                        '&.active': {
                            borderColor: theme.palette.primary.main,
                            '& [role="FormAreaLabel"]': {
                                color: `${theme.palette.primary.main} !important`
                            }
                        },
                        pt: 2,
                        width: '100%'
                    }}
                >
                    {dndIds
                        ? <Dnd ids={dndIds} onDragEnd={onDragEnd} modifiers={[restrictToParentElement]}>
                            {children}
                        </Dnd>
                        : children}
                </FormArea>
            </Box>
        </Grid>
    )
}

export default BaseElement