import { TabContext } from "@mui/lab"
import { Box } from "@mui/material"
import React from "react"
import EditorPanel from "./Editor/EditorPanel"
import { EditorContext } from "./useEditor"
import ViewerPanel from "./Viewer/ViewerPanel"

const MainFrame = () => {
    const { ui, theme } = React.useContext(EditorContext)
    return (
        <TabContext value={ui.editor ? 'edit' : 'view'}>
            <Box
                role="mainFrameContainer"
                sx={{
                    overflow: 'hidden',
                    flexGrow: 1,
                    // bgcolor: 'black',
                    borderBottomLeftRadius: theme.spacing(.4),
                    borderBottomRightRadius: theme.spacing(.4),
                }}
            >
                <EditorPanel />
                <ViewerPanel />
            </Box >
        </TabContext>
    )
}

export default MainFrame