import type { ApexOptions } from 'apexcharts'

import Chart from 'react-apexcharts'

import { useTheme } from '@mui/material'

import { useLangage } from '../../AppProvider'

import { convertDocumentType } from '../../utils/convertDocumentType'

type DocTypeRepartsChartsProps = {
  options: { type: number, nbTotal: number, nbEnabled: number }[] | undefined
}

export const DocTypeRepartsCharts = ({
  options: datas
}: DocTypeRepartsChartsProps) => {
  const { trad } = useLangage()
  const theme = useTheme()

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      // height: 350,
      stacked: true,
      toolbar: {
        tools: {
          download: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    colors: [
      theme.palette.secondary.main,
      theme.palette.primary.main
    ],
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    title: {
      text: ''
    },
    xaxis: {
      categories: datas?.map(d => d.type).map(t => convertDocumentType(t as any)),
    },
    yaxis: {
      title: {
        text: undefined
      },
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    }
  }

  const series = [
    {
      name: trad.no_viewable_documents,

      data: datas?.map(d => d.nbTotal - d.nbEnabled) ?? []
    },
    {
      name: trad.viewable_documents,
      data: datas?.map(d => d.nbEnabled) ?? []
    }
  ]

  if (!datas) return (<></>)
  return (
    <Chart
      options={options}
      series={series}
      type="bar"
    />
  )
}