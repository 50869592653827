import { OpenInFull, CloseFullscreen } from '@mui/icons-material'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import React from 'react'
import { EditorContext } from '../../useEditor'

const ScreenSize = () => {
    const { ui } = React.useContext(EditorContext)

    return (
        <ToggleButtonGroup
            value={ui.fullScreen ? 'fullScreen' : 'block'}
            onChange={ui.toggle}
            aria-label="screen size"
            size="small"
            exclusive
        >
            <ToggleButton value="fullScreen" aria-label="enter fullScreen" title="Plein écran" disabled={ui.fullScreen}>
                <OpenInFull />
            </ToggleButton>
            <ToggleButton value="block" aria-label="exit fullScreen" title="Quitter le plein écran" disabled={!ui.fullScreen}>
                <CloseFullscreen />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export default ScreenSize