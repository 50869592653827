import { FormatAlignCenterTwoTone, FormatAlignJustifyTwoTone, FormatAlignLeftTwoTone, FormatAlignRightTwoTone } from '@mui/icons-material';
import { FormControlLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography as MuiTypo } from '@mui/material';
import React from 'react';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';

const Typography = () => {
    const { activeElement } = React.useContext(EditorContext)

    return (
        <BaseConfig title={activeElement.type === 5 ? 'Paragraphe' : 'Titre'} subtitle={'Textes'}>
            <Style />
            <Alignment />
            <Gutter />
            <Value />
        </BaseConfig>
    )
}

const Alignment = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = (_: any, value: string) => {
        value && dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, align: value } })
    }
    return (
        <BaseForm title="Alignement">
            <ToggleButtonGroup
                value={activeElement.props.align}
                onChange={handleChange}
                size="small"
                exclusive
            >
                <ToggleButton value="left" aria-label="Gauche" title="Gauche" >
                    <FormatAlignLeftTwoTone fontSize="small" />
                </ToggleButton>
                <ToggleButton value="center" aria-label="Centré" title="Centré" >
                    <FormatAlignCenterTwoTone fontSize="small" />
                </ToggleButton>
                <ToggleButton value="right" aria-label="Droite" title="Droite" >
                    <FormatAlignRightTwoTone fontSize="small" />
                </ToggleButton>
                <ToggleButton value="justify" aria-label="Justifié" title="Justifié" >
                    <FormatAlignJustifyTwoTone fontSize="small" />
                </ToggleButton>
            </ToggleButtonGroup>
        </BaseForm>
    )
}

const Style = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = ({ target: { value } }: SelectChangeEvent<HTMLInputElement>, _: any) => {
        value && dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, variant: value } })
    }
    return (
        <BaseForm title="Style">
            {activeElement.type === 5 &&
                <Select
                    value={activeElement.props.variant}
                    onChange={handleChange}
                    MenuProps={{
                        sx: { zIndex: 20000 }
                    }}
                >
                    <MenuItem value="body1"><MuiTypo variant="body1">Paragraphe par défault</MuiTypo></MenuItem>
                    <MenuItem value="body2"><MuiTypo variant="body2">Paragraphe 2</MuiTypo></MenuItem>
                    <MenuItem value="caption"><MuiTypo variant="caption">Paragraphe 3</MuiTypo></MenuItem>
                    <MenuItem value="overline"><MuiTypo variant="overline">Paragraphe 4</MuiTypo></MenuItem>
                </Select>}
            {activeElement.type === 4 &&
                <Select
                    value={activeElement.props.variant}
                    onChange={handleChange}
                    MenuProps={{
                        sx: { zIndex: 20000 }
                    }}
                >
                    <MenuItem value="h4"><MuiTypo variant="h4">Titre 1</MuiTypo></MenuItem>
                    <MenuItem value="h5"><MuiTypo variant="h5">Titre 2</MuiTypo></MenuItem>
                    <MenuItem value="h6"><MuiTypo variant="h6">Titre 3</MuiTypo></MenuItem>
                </Select>}
        </BaseForm>
    )
}

const Gutter = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = (_: any, gutterBottom: boolean) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, gutterBottom } })
    }
    return (
        <BaseForm>
            <FormControlLabel control={
                <Switch
                    checked={activeElement.props.gutterBottom}
                    onChange={handleChange}
                />
            } label="Gouttière" />
        </BaseForm>
    )
}

const Value = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch.updateElement({ ...activeElement, value })
    }
    return (
        <BaseForm title="Valeur">
            <TextField
                multiline={activeElement.type === 5}
                fullWidth
                value={activeElement.value ?? ''}
                onChange={handleChange}
            />
        </BaseForm>
    )
}


export default Typography