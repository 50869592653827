import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { LineButton } from '../../type'
import { TableurContext } from '../../useTableur'

interface ButtonFunctionProps {
    button: LineButton<any> & { type: 'function' }
    data: any,
}

const ButtonFunction = ({ button, data }: ButtonFunctionProps) => {
    const context = React.useContext(TableurContext)
    const isDisabled = 'disabled' in button && button.disabled !== undefined ? button.disabled!({ ...context, data }) : false
    return (
        <Tooltip title={button.label} disableFocusListener={isDisabled} disableHoverListener={isDisabled} disableInteractive={isDisabled} disableTouchListener={isDisabled}>
            <span>
                <IconButton onClick={() => button.onClick({ ...context, data })} disabled={isDisabled}>
                    <button.icon />
                </IconButton>
            </span>
        </Tooltip>
    )
}

export default ButtonFunction