import type { ComponentWithElementProps } from "../../../types"

import React from "react"

import { Button, CircularProgress, Typography } from "@mui/material"

import { EditorContext } from "../../../useEditor"

import BaseElement from "../BaseElement"
import { DocumentLinkConfig, ExternalLinkConfig } from "./config"
import { DocumentRep } from "../../../.."

export const DocumentLink = ({ element }: ComponentWithElementProps) => {
    const { documents } = React.useContext(EditorContext)
    const doc = element.documentId ? documents.find(d => d.id === element.documentId) : null
    return (
        <BaseElement element={element} configObject={DocumentLinkConfig}>


            {element.documentId
                ? doc
                    ? <DocumentRep document={doc} onClick={() => { }} likeButton={false} />
                    : <CircularProgress />
                : <Typography variant="caption" color="GrayText">Aucun document sélectionné.</Typography>}

        </BaseElement>
    )
}

export const Externallink = ({ element }: ComponentWithElementProps) =>
    <BaseElement element={element} configObject={ExternalLinkConfig}>
        {element.value
            ? <Button component={'a'} href={element.value} target="_blank">
                {element.titre ?? element.value}
            </Button>
            : <Typography variant="caption" color="GrayText">Aucune adresse sélectionnée.</Typography>}

    </BaseElement>
