import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Add, DragHandle } from '@mui/icons-material';
import { Avatar, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Switch, TextField } from '@mui/material';
import React from 'react';
import Dnd from '../../../../commons/Dnd';
import { EditorElement } from '../../types';
import { CarouselConfig, ImageConfig } from '../Elements';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';
import { DeleteButton } from '../../..';

const Carousel = () => {
    return (
        <BaseConfig title={CarouselConfig.label} subtitle={'Textes'}>
            <Stepper />
            <ImageList />
        </BaseConfig>
    )
}

const Stepper = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)

    const handleChangeStepperVariant = ({ target: { value: stepperVariant } }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, stepperVariant } })
    }

    const handleChangeAutoPlay = (_: any, autoPlay: boolean) =>
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, autoPlay } })

    return (
        <BaseForm>
            <TextField
                label={'Progression label'}
                select
                fullWidth
                value={activeElement.props.stepperVariant}
                onChange={handleChangeStepperVariant}
                SelectProps={{ MenuProps: { sx: { zIndex: 30000 } } }}
            >
                <MenuItem value="progress">Barre de progression</MenuItem>
                <MenuItem value="dots">Points</MenuItem>
                <MenuItem value="text">Texte</MenuItem>
            </TextField>
            <FormControlLabel control={
                <Switch checked={activeElement.props.autPlay} onChange={handleChangeAutoPlay} />
            } label={'Lecture auto.'} />
        </BaseForm>
    )
}

const ImageList = () => {
    const { activeElement, dispatch, fileSelectorDispatch, files } = React.useContext(EditorContext)
    const availableFiles = files.filter(file => !activeElement.childs.map(child => child.props.src).includes(file.id)).filter(({ mime }: { mime: string }) => mime.startsWith('image'))

    const addImage = () => {
        fileSelectorDispatch.open({
            title: 'Choisir une image',
            active: activeElement.props.src ?? null,
            list: availableFiles,
            onSelect: ({ id: src }: any) => {
                fileSelectorDispatch.close()
                dispatch.addElement({ ...ImageConfig, defaultProps: { src } }, false)
            },
            onClose: () => fileSelectorDispatch.close()
        })
    }

    const onDrageEnd = (oldIndex: number, newIndex: number) => {
        dispatch.updateElement({ ...activeElement, childs: arrayMove(activeElement.childs, oldIndex, newIndex) })
    }

    return (
        <BaseForm title={'Liste des images'}>
            <List>
                <Dnd ids={activeElement.childs.map((child) => child.id)} onDragEnd={onDrageEnd}>
                    {activeElement.childs.map((child) => <Item key={child.id} element={child} />)}
                </Dnd>
                <ListItemButton onClick={addImage} disabled={!availableFiles || availableFiles.length === 0}>
                    <ListItemIcon>
                        <Add />
                    </ListItemIcon>
                    <ListItemText primary="Ajouter une image" />
                </ListItemButton>
            </List>
        </BaseForm>
    )
}

interface ItemProps {
    element: EditorElement
}
const Item = ({ element }: ItemProps) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: element.id })
    const { dispatch } = React.useContext(EditorContext)
    const style = {
        transform: CSS.Transform.toString(transform) as any,
        transition: transition as any,
    };

    const preventClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDelete = () => { dispatch.deleteChild(element.id) }
    return (

        <ListItem
            ref={setNodeRef}
            style={style} {...attributes}
            secondaryAction={<DeleteButton onDelete={handleDelete} />}
        >
            <ListItemIcon onClick={preventClick}>
                <DragHandle {...listeners} />
            </ListItemIcon>
            <ListItemIcon>
                <Avatar variant='rounded' src={`/download/${element.props.src}`} />
            </ListItemIcon>
        </ListItem>
    )
}

export default Carousel