import type { FileSelectionConfigObjectType, FileType } from "."

import React from "react"

type useFileSelectorType = (config: FileSelectionConfigObjectType) => [
    open: boolean,
    active: string | null,
    selected: string | null,
    dispatch: FileSelectorDispatchType
]

type FileSelectorDispatchType = {
    select: (fileId: string) => void
    selectAndValidate: (file: FileType) => void
    validate: () => void
    clear: () => void
    close: () => void

}

type FileSelectorContextType = {
    active: string | null
    selected: string | null
    dispatch: FileSelectorDispatchType
}



export const FileSelectorContext = React.createContext<FileSelectorContextType>({
    active: null,
    selected: null,
    dispatch: {
        select: (fileId) => { },
        selectAndValidate: (file) => { },
        validate: () => { },
        clear: () => { },
        close: () => { }
    }
})

export const useFileSelector: useFileSelectorType = (config) => {
    const [selected, setSelected] = React.useState<string | null>(null)
    const [active, setActive] = React.useState<string | null>(null)

    const dispatch = React.useMemo((): FileSelectorDispatchType => ({
        select: (fileId) => setSelected(fileId),
        selectAndValidate: (file) => config!.onSelect(file),
        validate: () => config!.onSelect(config!.list.find(({ id }) => id === selected)!),
        clear: () => config!.onClear!(),
        close: () => config!.onClose()
    }), [config, selected])

    React.useEffect(() => {
        setActive(config?.active ?? '')
        setSelected(config?.active ?? '')
    }, [config])

    return [Boolean(config), active, selected, dispatch]
}
