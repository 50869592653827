import React from "react"
import BaseConfig from "./BaseConfig"
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import { EditorContext } from "../../useEditor"
import { DocumentRep, Input } from "../../.."

const DocumentLink = () => {
    const { activeElement, documents, dispatch, theme } = React.useContext(EditorContext)
    const [open, toggleOpen] = React.useReducer(o => !o, false)
    const [search, setSearch] = React.useState('')

    const [selectedDocument, setSelectedDocument] = React.useState<string | undefined>(undefined)

    const handleSelectDocument = () => {
        dispatch.updateElement({ ...activeElement, documentId: selectedDocument })
        toggleOpen()
    }
    const handleDeleteDocument = () => {
        dispatch.updateElement({ ...activeElement, documentId: null })
    }

    React.useEffect(() => {
        setSearch('')
        setSelectedDocument(open ? (activeElement.documentId ?? undefined) : undefined)
    }, [open])
    return (
        <BaseConfig
            title={'Lien vers un document'}
            subtitle={'Lien'}
        >
            <Dialog
                open={open}
                onClose={toggleOpen}
                fullWidth
                maxWidth={'md'}
            >
                <DialogContent>
                    <Input
                        value={search}
                        onChange={({ target: { value } }) => setSearch(value)}
                        label="Rechercher un document"
                        fullWidth
                    />

                    <Box display={'flex'} flexDirection={'column'} gap={2} mt={2}>
                        {documents
                            .filter(document => !search || document.name.toLowerCase().includes(search.toLowerCase()))
                            .map(document =>
                                <Box
                                    p={1}
                                    sx={selectedDocument && selectedDocument === document.id ? {
                                        border: (theme) => `1px solid ${theme.palette.primary.main}`,
                                        borderRadius: 1
                                    } : undefined}>
                                    <DocumentRep
                                        document={document}
                                        likeButton={false}
                                        onClick={() => setSelectedDocument(document.id)} />
                                </Box>)}
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleOpen}>Annuler</Button>
                    <Button onClick={handleSelectDocument} disabled={!selectedDocument}>Sélectionner</Button>
                </DialogActions>
            </Dialog>
            <Button onClick={toggleOpen} variant='outlined'>
                Choisir un document
            </Button>
            {activeElement.documentId && <Button onClick={handleDeleteDocument} variant='outlined' color='error'>
                Supprimer le document
            </Button>}

        </BaseConfig>
    )
}

export default DocumentLink