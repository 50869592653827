import React from 'react'

import { Box, CircularProgress, Table, Typography } from '@mui/material'

import { TableurContext } from '../useTableur'

import Head from './Head'
import Body from './Body'


const Main = () => {
    const { dense, isLoading, datas, loading } = React.useContext(TableurContext)
    if ((isLoading && datas.length === 0) || loading) return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
            <Typography>Chargement des données en cours...</Typography>
        </Box>
    )
    return (
        <Table stickyHeader sx={{
            '& tr:last-child td': {
                borderBottom: 'none'
            }
        }}
            size={dense ? 'small' : undefined}
        >
            <Head />
            <Body />
        </Table>
    )
}

export default Main