import { Box, Grid, Typography } from "@mui/material"
import { ComponentWithElementProps } from "../../../types"
import BaseElement from "../BaseElement"
import ElementSwitcher from "../ElementSwitcher"
import { OngletItemConfig as objectConfig } from "./config"


export const OngletItem = ({ element }: ComponentWithElementProps) => {
    return (
        <BaseElement
            element={element}
            configObject={objectConfig}
            dndIds={element.childs.map(child => child.id)}>
            <Box display={'flex'} gap={1} mb={2} alignItems={'baseline'}>
                <Typography variant="body1">Titre de l'onglet :</Typography>
                <Typography variant={element.titre ? "body1" : "caption"} color="GrayText">{element.titre ?? 'Aucun titre'}</Typography>

            </Box>
            <Grid container spacing={2} direction={'column'}>
                {element.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
            </Grid>
        </BaseElement>
    )
}