import React from "react"

import { Button, ButtonProps, IconButton, Tooltip } from "@mui/material"
import { Delete } from "@mui/icons-material"

import { useLangage } from "../../AppProvider"

import DeleteModale from "./DeleteModal"

interface DeleteButtonProps {
    rounded?: boolean
    onDelete: Function
    label?: string
    disabled?: boolean
    tooltipTitle?: string
    size?: 'small' | 'medium' | 'large' | undefined
}

const DeleteButton = ({
    rounded = true,
    onDelete,
    label,
    disabled = false,
    tooltipTitle,
    size,
    ...rest
}: DeleteButtonProps & ButtonProps) => {
    const { trad } = useLangage()
    const [open, setOpen] = React.useState<boolean>(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => setOpen(false)
    const handleConfirmSuppression = () => {
        handleClose()
        onDelete()
    }

    return (
        <React.Fragment>
            <Tooltip
                title={tooltipTitle ?? trad.deleteElement}
            >
                {rounded
                    ? <IconButton
                        {...rest}
                        color="error"
                        onClick={handleOpen}
                        disabled={disabled}
                        size={size}
                    >
                        <Delete fontSize={size} />
                    </IconButton>
                    : <Button
                        {...rest}
                        color="error"
                        variant="outlined"
                        onClick={handleOpen}
                        disabled={disabled}
                        size={size}
                    >
                        <Delete fontSize={size} />  {label ?? trad.deleteElement}
                    </Button>}
            </Tooltip>
            <DeleteModale open={open} handleClose={handleClose} confirmDelete={handleConfirmSuppression} />
        </React.Fragment>
    )
}

export default DeleteButton