import { lazy } from "react";
import { BrowserRouter } from "react-router-dom";

import { Checklist, Group, Home, LocalHospital, MenuBook, PhotoSizeSelectActual, Public } from "@mui/icons-material";

import { AppProvider, useUser } from "../AppProvider";

const HomeCpt = lazy(() => import("./Components/Dashboard"));

const DocumentListCpt = lazy(() => import("./Components/Documents/List"));
const DocumentEditProcCpt = lazy(() => import("./Components/Documents/Edit/Procedure"));
const DocumentEditCourseCpt = lazy(() => import("./Components/Documents/Edit/CoursSlideShow"));

const FormationListCpt = lazy(() => import("./Components/Formation/List"));
const FormationEditCpt = lazy(() => import("./Components/Formation/Edit"));

const HospitalListCpt = lazy(() => import("./Components/Hospitals/List"));
const HospitalEditCpt = lazy(() => import("./Components/Hospitals/Edit"));
const ServiceEditCpt = lazy(() => import("./Components/Service"));

const ThematicsListCpt = lazy(() => import("./Components/Thematics/List"));
const ThematicsEditCpt = lazy(() => import("./Components/Thematics/Edit"));

const UserListCpt = lazy(() => import("./Components/Users/List"));
const UserEditCpt = lazy(() => import("./Components/Users/Edit"));
const UserFunctionCpt = lazy(() => import("./Components/UserFunction"));
const BackgroundImageCpt = lazy(() => import("./Components/ImageBackground"));
const TraductionCpt = lazy(() => import("./Components/Traduction"));

const AppAdmin = () => {
    return (
        <BrowserRouter>
            <AppProvider
                app="admin"
                routes={[
                    {
                        url: "/",
                        component: HomeCpt,
                        trad: 'home',
                        isLeft: true,
                        icon: Home,
                    },
                    'sep',
                    {
                        url: "/documents",
                        component: DocumentListCpt,
                        trad: 'documents_leftnav',
                        isLeft: true,
                        role: 'documents',
                        icon: Group
                    },
                    {
                        url: "/documents/1/:id",
                        component: DocumentEditProcCpt,
                        role: 'documents',
                    },
                    {
                        url: "/documents/2/:id",
                        component: DocumentEditCourseCpt,
                        role: 'documents',
                    },
                    {
                        url: "/formations",
                        component: FormationListCpt,
                        role: 'formations',
                        trad: 'formations',
                        isLeft: true,
                        icon: MenuBook
                    },
                    {
                        url: "/formations/:id",
                        component: FormationEditCpt,
                        role: 'formations',
                    },
                    'sep',

                    {
                        url: "/hospitals",
                        component: HospitalListCpt,
                        role: 'hospitals',
                        trad: 'hospital_leftnav',
                        isLeft: true,
                        icon: LocalHospital
                    },
                    {
                        url: "/hospitals/:id",
                        component: HospitalEditCpt,
                        role: 'hospitals',
                    },
                    {
                        url: "/services/:id",
                        component: ServiceEditCpt,
                        role: 'hospitals',
                    },
                    'sep',
                    {
                        url: "/thematics",
                        component: ThematicsListCpt,
                        role: 'thematics',
                        trad: 'Thematics',
                        isLeft: true,
                        icon: Checklist
                    },
                    {
                        url: "/thematics/:id",
                        component: ThematicsEditCpt,
                        role: 'thematics',
                    },
                    'sep',
                    {
                        url: "/users",
                        component: UserListCpt,
                        role: 'users',
                        trad: 'userList',
                        isLeft: true,
                        icon: Group
                    },
                    {
                        url: '/users/:id',
                        component: UserEditCpt,
                        role: 'users',
                        isLeft: false,
                    },
                    {
                        url: "/user-functions",
                        component: UserFunctionCpt,
                        role: 'usersFunctions',
                        trad: 'userFunctions',
                        isLeft: true,
                        icon: Group
                    },
                    'sep',
                    {
                        url: "/backgrounds",
                        component: BackgroundImageCpt,
                        role: 'backgrounds',
                        trad: 'backgrounds',
                        isLeft: true,
                        icon: PhotoSizeSelectActual
                    },
                    {
                        url: "/traductions",
                        component: TraductionCpt,
                        role: 'traductions',
                        trad: 'translationList',
                        isLeft: true,
                        icon: Public,
                    },
                ] as any}>
            </AppProvider>
        </BrowserRouter>
    )
}

export default AppAdmin;