import React from 'react'
import { StepItemConfig } from '../Elements/StepItem'
import BaseConfig from './BaseConfig'
import { GridContainerConfig } from '../Elements'

const StepItem = () => {

    return (
        <BaseConfig
            title={StepItemConfig.label}
            subtitle={'Widgets'}
            adder={[GridContainerConfig]}
        >
        </BaseConfig>

    )
}

export default StepItem