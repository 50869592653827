import { NoImage } from "../../../.."
import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import { ImageConfig } from "./config"



interface ImageProps {
    element: EditorElement
}
export const Image = ({ element }: ImageProps) => {
    return (
        <BaseElement element={element} configObject={ImageConfig}>
            {element.props.src
                ? <img style={{ width: '100%', height: '100%', ...{ ...element.props } }} src={`/download/${element.props.src}`} draggable={false} alt="" />
                : <NoImage />}
        </BaseElement>
    )
}