import { Adjust, Circle } from '@mui/icons-material';
import { FormControlLabel, IconButton, MenuItem, Switch, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { TexteConfig } from '../Elements';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';
import { FormArea } from '../../..';

const Alert = () => {

    return (
        <BaseConfig
            title={'Alerte'}
            subtitle={'Widgets'}
            adder={[TexteConfig]}
        >
            <Aspect />
            <SubHeader />
        </BaseConfig>
    )
}

const Aspect = () => {
    const { activeElement, dispatch, theme } = React.useContext(EditorContext)
    const handleChangeVariant = ({ target: { value: variant } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, variant } })
    }
    const handleSelect = (severity: string) => dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, severity } })
    return (
        <BaseForm title="Aspect">
            <FormArea sx={{ mt: 2, mb: 2 }} innerSx={{ display: 'flex', justifyContent: 'space-between', m: 1 }} label={'Couleur'}>
                {['primary', 'secondary', 'error', 'info', 'success', 'warning'].map((color) =>
                    <IconButton onClick={() => handleSelect(color)} color={color as "primary" | "secondary" | "error" | "info" | "success" | "warning"}>
                        {color === activeElement.props.severity ? <Adjust /> : <Circle />}
                    </IconButton>
                )}
            </FormArea>
            <TextField
                fullWidth
                select
                label={'Type'}
                value={activeElement.props.variant}
                onChange={handleChangeVariant}
                SelectProps={{
                    MenuProps: { sx: { zIndex: 20000 } }
                }}
            >
                {[{ value: 'outlined', label: 'Borduré' }, { value: 'filled', label: 'Plein' }, { value: 'standard', label: 'Plein, pastel' }].map(variant =>
                    <MenuItem value={variant.value}>{variant.label}</MenuItem>)}
            </TextField>
        </BaseForm >
    )
}

const SubHeader = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = (_: any, title: boolean) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, title: title === true ? '' : undefined } })
    }
    return (
        <BaseForm>
            <FormControlLabel control={<Switch
                checked={activeElement.props.title !== undefined}
                onChange={handleChange}
            />} label="Titre" />
            {activeElement.props.title !== undefined &&
                <TextField
                    sx={{ mt: 1 }}
                    label="Titre"
                    fullWidth
                    value={activeElement.props.title}
                    onChange={({ target: { value: title } }) => dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, title } })}
                />}
        </BaseForm>
    )
}


export default Alert