import { IconButton } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { DataFunctionOption, LineButton } from '../../type'
import { Link as LinkIcon } from '@mui/icons-material'

interface NavButtonProps {
    button: LineButton<any> & { type: 'nav' }
    dataFunctionOptions: DataFunctionOption<any>
}
const NavButton = ({ button: b, dataFunctionOptions }: NavButtonProps) => {
    return (
        <IconButton
            component={Link}
            to={'url' in b
                ? b.url.replace(':id', dataFunctionOptions.data.id)
                : 'urlFn' in b
                    ? b.urlFn(dataFunctionOptions)
                    : ''}
        >
            {'icon' in b && b.icon !== undefined
                ? <b.icon fontSize="small" />
                : <LinkIcon fontSize={'small'} />}
        </IconButton>
    )
}

export default NavButton