import { TextField } from '@mui/material';
import React from 'react';
import { EditorContext } from '../../useEditor';
import { OngletItemConfig } from '../Elements/OngletItem';
import BaseConfig, { BaseForm } from './BaseConfig';

const OngletItem = () => {

    return (
        <BaseConfig
            title={OngletItemConfig.label}
            subtitle={'Widgets'}
        >
            <Titre />
        </BaseConfig>
    )
}

const Titre = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)
    const handleChange = ({ target: { value: titre } }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch.updateElement({ ...activeElement, titre })
    }
    return (
        <BaseForm>
            <TextField
                label="Titre de l'onglet"
                fullWidth
                value={activeElement.titre ?? ''}
                onChange={handleChange}
            />
        </BaseForm>
    )
}

export default OngletItem