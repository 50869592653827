import React from 'react'
import { Column } from '../../type'
import { Avatar } from '@mui/material'
import { TableurContext } from '../../useTableur'

interface AvatarCellProps {
    column: Column<any>
    data: any
}
const AvatarCell = ({ data, column: c, ...rest }: AvatarCellProps) => {
    const context = React.useContext(TableurContext)
    if (!c.avatar) return <></>
    return (
        <Avatar
            src={`/download/${typeof c.avatar === 'string'
                ? c.avatar
                : 'src' in c.avatar
                    ? typeof c.avatar.src === 'function'
                        ? c.avatar.src({ data, field: c.field, value: data[c.field], ...context! })
                        : c.avatar!.src
                    : undefined}`}
            variant={c.avatar && typeof c.avatar !== 'string'
                ? typeof c.avatar.variant === 'function'
                    ? c.avatar.variant({ data, field: c.field, value: data[c.field], ...context! })
                    : c.avatar.variant
                : undefined}
            {...rest}
        />
    )
}

export default AvatarCell