import type { Column } from '../../type'

import React from 'react'

import { AvatarGroup } from '@mui/material'

import { TableurContext } from '../../useTableur'

import AvatarCell from './AvatarCell'

interface AvatarGroupCellProps {
    column: Column<any>
    data: any
}
const AvatarGroupCell = ({ data, column: c }: AvatarGroupCellProps) => {
    const context = React.useContext(TableurContext)
    if (!c.groupAvatar) return <></>

    return (<>
        <AvatarGroup>
            {c.groupAvatar({ data, field: c.field, value: data[c.field], ...context! }).map((a, i) =>
                <AvatarCell key={`${c.field}${i}`} column={{ ...c, avatar: a }} data={data} />
            )}
        </AvatarGroup>
    </>
    )
}

export default AvatarGroupCell