import React from 'react'
import { TableurContext } from '../useTableur'
import { TabContext, TabList } from '@mui/lab'
import { Box, Tab } from '@mui/material'

const TabsContainer = () => {
    const context = React.useContext(TableurContext)
    const { tabs, activeTab, dispatch } = context

    return (
        <Box sx={theme => ({
            backgroundColor: theme.palette.background.paper,
        })}>
            <TabContext value={activeTab}>
                <TabList onChange={(_, value) => dispatch.setActiveTab(value)}>
                    {tabs!.map((t) =>
                        <Tab
                            key={'key' in t ? t.key : t.label}
                            value={'key' in t ? t.key : t.label}
                            label={'label' in t
                                ? t.label
                                : typeof t.render === 'function'
                                    ? t.render({ ...context, isActive: (t.key === activeTab) })
                                    : t.render}
                        />)}
                </TabList>
            </TabContext>
        </Box>
    )
}

export default TabsContainer