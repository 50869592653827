import { useClient } from "../../AppProvider"
import { FileType } from "../FileSeclector"
import type { hookType } from "./types"

import React from "react"


const useHook: hookType = ({ sliceSize = 10 * 1024 * 1024, returnFile, field, id }) => {
    const { sendFile } = useClient()
    const [drag, setDrag] = React.useState<boolean>(false)

    const onDragEnter = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        drag !== true && setDrag(true)
    }
    const onDragLeave = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setDrag(false)
    }
    const onDrop = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setDrag(false)
        if (event.dataTransfer?.files) {
            const { files } = event.dataTransfer
            for (let i = 0, mx = files.length; i < mx; i++) {
                const file = files[i]
                sendFile('File', field, file, id).then((msg: any) => {
                    if (msg.wsCode === 200) returnFile(msg.payload)
                })
            }
        }
    }
    const addFileToDoc = React.useRef<any>()

    addFileToDoc.current = (file: FileType) => returnFile(file)

    React.useEffect(() => {
        window.addEventListener('dragover', onDragEnter)
        window.addEventListener('dragenter', onDragEnter)
        window.addEventListener('dragleave', onDragLeave)
        window.addEventListener('dragend', onDragLeave)
        window.addEventListener('drop', onDrop)

        return () => {
            window.removeEventListener('dragover', onDragEnter)
            window.removeEventListener('dragenter', onDragEnter)
            window.removeEventListener('dragleave', onDragLeave)
            window.removeEventListener('dragend', onDragLeave)
            window.removeEventListener('drop', onDrop)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnFile])
    return {
        drag
    }
}

export default useHook