import { AlignHorizontalCenter, AlignHorizontalLeft, AlignHorizontalRight, AlignVerticalBottom, AlignVerticalCenter, AlignVerticalTop, VerticalAlignCenter } from '@mui/icons-material';
import { Box, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Slider, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { ChangeEvent } from 'react'
import { GridItemConfig } from '../Elements';
import { EditorContext } from '../../useEditor';
import BaseConfig, { BaseForm } from './BaseConfig';

const GridContainer = () => {
    return (
        <BaseConfig title={'Grille'} subtitle={'Conteneur'} adder={[GridItemConfig]}>
            <Spacing />
            <Direction />
            <AlignmentH />
            <AlignmentV />
        </BaseConfig>
    )
}

const Spacing = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)

    const handleChange = (_: any, value: number | number[], __: any) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, spacing: Number(value as number) } })
    };
    return (
        <BaseForm>
            <FormLabel component="legend">Espacement</FormLabel>
            <Slider
                value={activeElement.props.spacing}
                onChange={handleChange}
                min={0}
                max={5}
                step={1}
            />
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={activeElement.props.spacing}>
                        {[1, 2, 3].map((value) => (
                            <Grid key={value} item xs={true}>
                                <Paper
                                    sx={{
                                        height: 50,
                                        width: '100%',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light' ? '#1A2027' : '#fff',
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </BaseForm>
    )
}

const Direction = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)

    const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, direction: value } })
    };
    return (
        <BaseForm>
            <FormLabel component="legend">Direction</FormLabel>
            <RadioGroup
                name="spacing"
                aria-label="spacing"
                value={activeElement.props.direction}
                onChange={handleChange}
                row
            >
                {[{ id: 'row', label: 'Ligne' }, { id: 'column', label: 'Colonne' }].map((value) => (
                    <FormControlLabel
                        key={value.id}
                        value={value.id}
                        control={<Radio />}
                        label={value.label}
                    />
                ))}
            </RadioGroup>
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" direction={activeElement.props.direction} spacing={2}>
                        {[1, 2, 3].map((value) => (
                            <Grid key={value} item xs={true}>
                                <Paper
                                    sx={{
                                        height: 50,
                                        width: '100%',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light' ? '#1A2027' : '#fff',
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </BaseForm>
    )
}

const AlignmentH = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)

    const handleChange = (_: any, value: string) => {
        value && dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, alignItems: value } })
    };
    return (
        <BaseForm>
            <FormLabel component="legend">Alignement vertical</FormLabel>
            <ToggleButtonGroup
                value={activeElement.props.alignItems}
                onChange={handleChange}
                size="small"
                exclusive
            >
                <ToggleButton value="flex-start" aria-label="En haut" title="En haut" >
                    <AlignVerticalTop fontSize="small" />
                </ToggleButton>
                <ToggleButton value="center" aria-label="Centré" title="Centré" >
                    <AlignVerticalCenter fontSize="small" />
                </ToggleButton>
                <ToggleButton value="flex-end" aria-label="En bas" title="En bas" >
                    <AlignVerticalBottom fontSize="small" />
                </ToggleButton>
            </ToggleButtonGroup>
            <Grid sx={{ flexGrow: 1 }} container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems={activeElement.props.alignItems} spacing={2}>
                        {[1, 2, 3].map((value) => (
                            <Grid key={value} item xs={true}>
                                <Paper
                                    sx={{
                                        marginTop: 'auto',
                                        height: 40 * value / 2,
                                        width: '100%',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light' ? '#1A2027' : '#fff',
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </BaseForm>
    )
}

const AlignmentV = () => {
    const { activeElement, dispatch } = React.useContext(EditorContext)

    const handleChange = (_: any, value: string) => {
        value && dispatch.updateElement({ ...activeElement, props: { ...activeElement.props, justifyContent: value } })
    };
    return (
        <BaseForm>
            <FormLabel component="legend">Alignement horizontal</FormLabel>
            <Box display={'flex'} gap={2}>

                <ToggleButtonGroup
                    value={activeElement.props.justifyContent}
                    onChange={handleChange}
                    size="small"
                    exclusive
                >
                    <ToggleButton value="flex-start" aria-label="A gauche" title="A gauche" >
                        <AlignHorizontalLeft fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="Centré" title="Centré" >
                        <AlignHorizontalCenter fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value="flex-end" aria-label="A droite" title="A droite" >
                        <AlignHorizontalRight fontSize="small" />
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    value={activeElement.props.justifyContent}
                    onChange={handleChange}
                    size="small"
                    exclusive
                >

                    <ToggleButton value="space-between" aria-label="Espacé (entre)" title="Espacé (entre)" >
                        <VerticalAlignCenter fontSize="small" sx={{ transform: 'rotate(90deg)' }} />
                    </ToggleButton>
                    <ToggleButton value="space-around" aria-label="Espacé (entre et bordure)" title="Espacé (entre et bordure)" >
                        <VerticalAlignCenter fontSize="small" sx={{ transform: 'rotate(90deg)' }} />
                    </ToggleButton>
                    <ToggleButton value="space-evenly" aria-label="Espacé (réparti)" title="Espacé (réparti)" >
                        <VerticalAlignCenter fontSize="small" sx={{ transform: 'rotate(90deg)' }} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Grid sx={{ flexGrow: 1 }} container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Grid container justifyContent={activeElement.props.justifyContent} spacing={2}>
                        {[1, 2, 3].map((value) => (
                            <Grid key={value} item xs={2}>
                                <Paper
                                    sx={{
                                        height: 50,
                                        width: '100%',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light' ? '#1A2027' : '#fff',
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </BaseForm>
    )
}

export default GridContainer