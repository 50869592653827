
import React from "react"

import { Dialog, DialogContent, Grid, DialogActions, Button, Slide, AppBar, IconButton, Toolbar, Typography } from "@mui/material"
import { Close } from "@mui/icons-material"
import { TransitionProps } from "@mui/material/transitions"

import { useLangage } from "../../AppProvider"

import { FileItem, type FileSelectionConfigObjectType, FileSelectorContext, useFileSelector } from '.'

interface FileSelectorProps {
    config?: FileSelectionConfigObjectType
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const FileSelector = ({ config }: FileSelectorProps) => {
    const { trad } = useLangage()

    const [open, active, selected, dispatch] = useFileSelector(config)

    React.useEffect(() => {

    }, [config])

    return (
        <FileSelectorContext.Provider value={{ active, selected, dispatch }}>
            <Dialog
                fullScreen
                open={open}
                onClose={dispatch.close}
                TransitionComponent={Transition}
                sx={{ zIndex: 30000 }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={dispatch.close}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {config?.title ?? ''}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={2}>
                        {config?.list.map(file => <FileItem key={file.id} file={file} />)}
                        {config?.list.length === 0 &&
                            <Grid item xs={12}>
                                <Typography variant="caption">Aucun fichier</Typography>
                            </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={dispatch.validate} color="primary" disabled={!selected && !active}>
                        {trad.select}
                    </Button>
                    {config?.onClear && <Button variant="outlined" onClick={dispatch.clear} color="primary">
                        {trad.noFile}
                    </Button>}
                    <Button onClick={dispatch.close} color="secondary" variant="outlined">
                        {trad.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
        </FileSelectorContext.Provider>
    )
}