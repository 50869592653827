import { EditorElement } from "../../../types"
import BaseElement from "../BaseElement"
import { VideoConfig } from "./config"

interface VideoProps {
    element: EditorElement
}
export const Video = ({ element }: VideoProps) => {
    return (
        <BaseElement element={element} configObject={VideoConfig}  >
            <video {...element.props} style={{ width: '100%', height: '100%' }} src={`/download/${element.props.src}`} controls />
        </BaseElement>
    )
}