import React, { PropsWithChildren } from 'react'

import { Box, Typography } from '@mui/material'
import { Upload } from '@mui/icons-material'

import ThemeContext from '../../theme/ThemeContext'
import Context from './context'
import hook from './hook'

import { ElementProps } from './types'

const Element = ({ config, children }: ElementProps & PropsWithChildren) => {
    const contextValue = hook(config)
    const { theme } = React.useContext(ThemeContext)
    return (
        <Context.Provider value={contextValue}>
            {children}
            <Box sx={{
                display: contextValue.drag ? 'flex' : 'none',
                position: 'fixed',
                top: 0, left: 0, right: 0, bottom: 0,
                zIndex: 99999,
                flexDirection: 'column',
                backgroundColor: 'rgba(0,0,0,.4)'
            }}>
                <Box display={'flex'} flexGrow={1} sx={{ m: 2, border: `4px dashed ${theme.palette.primary.main}`, borderRadius: theme.spacing(2) }}>
                    <Box sx={{ flexGrow: 1, backgroundColor: 'rgba(0,0,0,.4)', borderRadius: theme.spacing(2) }} display={'flex'} alignItems="center" justifyContent={'center'}>
                        <Box display={'flex'} flexDirection={'column'} alignItems="center" color={'white'}>
                            <Typography variant="h4"><Upload fontSize="large" /></Typography>
                            <Typography variant="h4">Deposez vos fichiers ici...</Typography>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Context.Provider>
    )
}

export default Element