import { Grid } from "@mui/material"
import { ComponentWithElementProps } from "../../../types"
import BaseElement from "../BaseElement"
import ElementSwitcher from "../ElementSwitcher"
import { StepItemConfig } from "./config"


export const StepItem = ({ element }: ComponentWithElementProps) => {
    return (
        <BaseElement
            element={element}
            configObject={StepItemConfig}
            dndIds={element.childs.map(child => child.id)}>
            <Grid container spacing={2} direction={'column'}>
                {element.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
            </Grid>
        </BaseElement>
    )
}